import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CircularProgress from "@material-ui/core/CircularProgress";

function CtSingle() {
    let history = useHistory();

    const [data, setData] = useState("");

    const id = history.location.pathname.split("/ct-patients-single/")[1];

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/ct-patient-single/${id}`, {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(res => {
            let ct_type = "";

            res.ct_type.map((item, i) => res.ct_type.length === i + 1 ? ct_type += item.name : ct_type += item.name + "-and");

            res.ct_type = ct_type;

            setData(res)
        })
        .catch(err => console.log(err))
    }, []);

    function dateFormat (date, yearShow) {
        if (date === '-') return date;
    
        var arr = date.split('-');
        var month = arr[1];
        var year = arr[0];
    
        if (month === '01') {
            month = "Հունվար";
        } else if (month === '02') {
            month = "Փետրվար";
        } else if (month === '03') {
            month = "Մարտ";
        } else if (month === '04') {
            month = "Ապրիլ";
        } else if (month === '05') {
            month = "Մայիս";
        } else if (month === '06') {
            month = "Հունիս";
        } else if (month === '07') {
            month = "Հուլիս";
        } else if (month === '08') {
            month = "Օգոստոս";
        } else if (month === '09') {
            month = "Սեպտեմբեր";
        } else if (month === '10') {
            month = "Հոկտեմբեր";
        } else if (month === '11') {
            month = "Նոյեմբեր";
        } else if (month === '12') {
            month = "Դեկտեմբեր";
        }
        
        if (yearShow === 'no') {
            return arr[2] + ' ' + month;
        } else {
            return arr[2] + ' ' + month + ', '+ year;
        }
    }

    const timeFormat = time => new Date(time).toLocaleTimeString([], {timeStyle: 'short'});
    
    return (
        <div>
            {data ? <List>
                <ListItem>
                    <KeyboardBackspaceIcon onClick={() => history.push("/ct-patients")} style={{cursor: "pointer"}} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Անուն Ազգանուն" secondary={data.name_surname} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Հետազության օրը" secondary={data.research_day ? dateFormat(data.research_day) : null} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Հետազոտության ժամը" secondary={data.time ? timeFormat(data.time) : null} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Ծննդյան օր" secondary={data.birthday ? dateFormat(data.birthday) : null} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Անձնագրի սերիա" secondary={data.passport} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Ct տեսակ" component="div" secondary={""} />
                </ListItem>
                
                <ul style={{marginBottom: "15px", marginTop: "-10px"}}>
                    {data.ct_type.split("-and").length ? data.ct_type.split("-and").map((item, key) => <li key={key}>{item}</li>) : null}
                </ul>

                <ListItem>
                    <ListItemText primary="Հեռախոսահամար" secondary={data.phone} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="ՈՒղեգրող բուժ հաստատություն" secondary={data.referral_medical_institution} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Բուժող բժիշկ" secondary={data.doctor} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Ալերգիա յոդից" secondary={data.iodine === "yes" ? "Այո" : "Ոչ"} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Շաքարային դիաբետ" secondary={data.diabetes === "yes" ? "Այո" : "Ոչ"} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Պետպատվեր" secondary={data.state_order === "yes" ? "Այո" : "Ոչ"} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Հիվանդության տեսակ" secondary={data.type_of_disease} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Քաշ" secondary={data.weight} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Հասակ" secondary={data.height ? data.height + " սմ" : null} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Էլ. հասցե" secondary={data.email} />
                </ListItem>

                <ListItem>
                    <ListItemText primary="Բարեգործություն" secondary={data.charity === "yes" ? "Այո" : "Ոչ"} />
                </ListItem>

                {data.file_name ? <a target="_blank" href={process.env.REACT_APP_STORAGE_URL + "/ct_files/" + data.file_name}>
                    <ListItem>
                        <ListItemText primary="Կից ֆայլեր" secondary={data.file_name} />
                    </ListItem>
                </a> : <ListItem>
                        <ListItemText primary="Կից ֆայլեր" secondary={data.file_name} />
                    </ListItem>}

                <ListItem>
                    <ListItemText primary="Նշումներ" secondary={data.notes} />
                </ListItem>
            </List> : <CircularProgress />}
        </div>
    )
}

export default CtSingle;