import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

import Avatar from '@material-ui/core/Avatar';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { green } from '@material-ui/core/colors';

//Icons
import SaveIcon from '@material-ui/icons/Save';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function Upload(props) {
  const [open, setOpen] = React.useState(true);
  const [progressNumber, setProgressNumber] = React.useState(0);
  const [uploadedData, setUploadedData] = React.useState([]);
  const [successAdding, setSuccessAdding] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    props.openForm(false);
  };

  const uploadFile = e => {
    let data = new FormData();
    data.append('file', e.target.files[0]);

    let request = new XMLHttpRequest();
    request.open('POST', `${process.env.REACT_APP_API_URL}/file-upload`);
    request.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("appname_token"));

    request.upload.addEventListener('progress', function(e) {
      let percent_complete = (e.loaded / e.total)*100;
      
      if (percent_complete < 100) {
        setProgressNumber(percent_complete);
      } else {
        setProgressNumber(100);
      }
    });

    request.addEventListener('load', function(e) {
      if (request.status === 200) {
        let res = JSON.parse(request.response);
        
        if (res.status === "Success") {
          setUploadedData([...uploadedData, {"name": res.name}]);
        }

        window.setTimeout(function () {
          setProgressNumber(0);
        }, 1000);
      }

      e.target.value = "";
    });

    request.send(data);
  }

  const deleteFile = name => {
    fetch(`${process.env.REACT_APP_API_URL}/file-delete/${name}`, {
      method: 'DELETE',
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("appname_token")
      }
    }).then(response => response.json())
      .then(res => {
        if (res.status === "Success") {
          let items = uploadedData.filter(item => item.name !== name);
          
          setUploadedData(items);
        }
      })
      .catch(error => console.error(error))
  }

  const removeAll = () => {
    fetch(`${process.env.REACT_APP_API_URL}/file-delete-all`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + localStorage.getItem("appname_token")
      },
      method: 'POST',
      body: JSON.stringify({files: uploadedData})
    }).then(response => response.json())
      .then(res => {
        if (res.status === "Success") {
          setUploadedData([]);
        }
      })
      .catch(error => console.error(error))
  }

  const cutString = str => {
    if (str.length > 45) {
      let arr = str.split(".");
      let ext = arr[arr.length - 1];
      arr.pop();
    
      return arr.join().slice(0, 44) + "..." + ext;
    } else {
      return str;
    }
  }

  const confirmData = () => {
    fetch(`${process.env.REACT_APP_API_URL}/store-files`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + localStorage.getItem("appname_token")
      },
      method: 'POST',
      body: JSON.stringify({files: uploadedData, user_id: props.userId})
    }).then(response => response.json())
      .then(res => {        
        if (res.status === "Success") {
          setUploadedData([]);
          setSuccessAdding(true);

          setTimeout(function () {
            setSuccessAdding(false);
          }, 1500);
        }
      })
      .catch(error => console.error(error))
  }

  return (
    <div>
      <Dialog fullWidth aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={!uploadedData.length ? handleClose : null}>
          Ներբեռնել
        </DialogTitle>
        <DialogContent dividers>
            <Button variant="contained" component="label" color="primary">
              Ընտրել ֆայլը
              <input type="file" hidden onChange={uploadFile} />
            </Button>

            <LinearProgressWithLabel value={progressNumber} />

            {successAdding ? <Typography variant="subtitle2" gutterBottom style={{color: '#28a745', marginTop: '10px'}}>Ավելացումը կատարվել է հաջողությանբ</Typography> : null}
            
            <List dense={false}>
              {uploadedData.length ? uploadedData.map((item, key) => {
                return (
                  <ListItem key={key}>
                    <ListItemAvatar>
                      <Avatar style={{backgroundColor: green[500]}}>
                        <DoneIcon style={{ color: "white" }} />
                      </Avatar>
                    </ListItemAvatar>
                    
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <ListItemText
                        primary={cutString(item.name)}
                      />
                    </a>

                    <ListItemSecondaryAction>
                      <IconButton onClick={() => deleteFile(item.name)} edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              }) : null}
            </List>

        </DialogContent>
        <DialogActions>
          <Button onClick={confirmData} disabled={!uploadedData.length ? true : false} variant="contained" color="primary" startIcon={<SaveIcon />}>
            Հաստատել
          </Button>
          
          <Button onClick={removeAll} disabled={!uploadedData.length ? true : false} variant="contained" color="secondary" startIcon={<HighlightOffIcon />}>
            Ջնջել բոլորը
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}