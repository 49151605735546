import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CreatePatient from './CreatePatient';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import dateFormat from '../usefulFunction/DateFormat';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from "@material-ui/core/Checkbox";

//Icons
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PrintIcon from "@material-ui/icons/Print";
import RefreshIcon from '@material-ui/icons/Refresh';

import Patient from './Patient';
import EditPatient from './EditPatient';

import Tooltip from '@material-ui/core/Tooltip';

import ReactToPrint from 'react-to-print';

export default function PetSingle(props) {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isShowPatient, setIsShowPatient] = useState(false);
    const [isEditPatient, setIsEditPatient] = useState(false);
    const [patientId, setpatientId] = useState(0);
    const [deleteBtnDisabled, setDeleteBtnDisabled] = useState(false);
    const [isDeleteSuccess, setIsDeleteSuccess] = useState(null);
    const [isDataAdded, setIsDataAdded] = useState(false);
    const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

    const [showPrintData, setShowPrintData] = useState(false);

    const [printDataState, setPrintDataState] = useState("");

    let componentRef = useRef(null);

    useEffect(() => {        
        fetch(`${process.env.REACT_APP_API_URL}/patients/${props.match.params.itemId}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(json => {
            setIsDataLoaded(true)
            setData(json)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const createData = patientData => {

        if (patientData.isCreated) {
            setIsDataAdded(true)
            
            setTimeout(function () {
                setIsDataAdded(false)
            }, 2000)

            setData([...data, {id: patientData.id, name_surname: patientData.printname, birthday: patientData.printbirthday}])
        }
        
        if (!patientData.status) {
            setIsModalOpen(false);
        }
    }

    const showPatient = id => {
        setIsShowPatient(true)
        setpatientId(id)
    }

    const editPatient = id => {
        setIsEditPatient(true)
        setpatientId(id)
    }

    const deletePatient = id => {
        if (window.confirm("Համոզված եք որ ուզում եք ջնջնել")) {
            setDeleteBtnDisabled(true);

            fetch(`${process.env.REACT_APP_API_URL}/patient/delete/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + localStorage.getItem("appname_token")
                }
            })
                .then(res => res.json())
                .then(json => {
                    if (json.status === "Fail") {
                        setIsDeleteSuccess(false)

                        setTimeout(function () {
                            setIsDeleteSuccess(null)
                        }.bind(this), 2000);
                    } else {

                        let filtered = data.filter(item => item.id !== id)

                        setData(filtered)

                        setIsDeleteSuccess(true)

                        setTimeout(function () {
                            setIsDeleteSuccess(null)
                        }.bind(this), 2000
                        );
                    }

                    setDeleteBtnDisabled(false);
                }).catch(err => {
                    console.log(err)

                    setDeleteBtnDisabled(false);
                })
        }
    }

    const closePatient = e => {
        setIsShowPatient(e.status);
    }

    const closeEditPatient = e => {
        setIsEditPatient(e.status);
    }

    const updateStatus = e => {
        if (e === "Success") {
            setIsUpdateSuccess(true);
        } else {
            setIsUpdateSuccess(false);
        }

        setIsEditPatient(false);
        
        setTimeout(function () {
            setIsUpdateSuccess(false)
        }.bind(this), 2500);
    }

    const printData = data => {
        if (data.length) {
            return (
                data.map(row => (
                    <TableRow key={row.id}>
                        <TableCell></TableCell>
                        <TableCell>{row.name_surname ? row.name_surname : "Տվյալը բացակայում է"}</TableCell>
                        <TableCell>{row.birthday ? dateFormat(row.birthday) : "Տվյալը բացակայում է"}</TableCell>
                        <TableCell>
                            <Button variant="contained" color="primary"
                                startIcon={<VisibilityIcon />}
                                onClick={() => showPatient(row.id)}>
                                Ավելին
                            </Button>
                        </TableCell>
                        <TableCell>
                            <Button variant="contained" startIcon={<EditIcon />} onClick={() => editPatient(row.id)}>
                                Փոխել
                            </Button>
                        </TableCell>
                        <TableCell>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => deletePatient(row.id)}
                                startIcon={<DeleteIcon />}
                                disabled={deleteBtnDisabled}
                            >
                                Ջնջել
                            </Button>
                        </TableCell>
                    </TableRow>
                ))
            )
        } else {
            return <TableRow><TableCell>Տվյալններ չկա</TableCell></TableRow>
        }
    }

    const refreshData = () => {
        setIsDataLoaded(false)

        fetch(`${process.env.REACT_APP_API_URL}/patients/${props.match.params.itemId}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(json => {
            setIsDataLoaded(true)
            setData(json)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <React.Fragment>
            {isModalOpen ? <CreatePatient isOpen={isModalOpen} id={props.match.params.itemId} onClick={data => createData(data)} /> : null}

            {isShowPatient ? <Patient isOpen={isShowPatient} id={patientId} onClick={e => closePatient(e)} /> : null}

            {isEditPatient ? 
                <EditPatient 
                    isOpen={isEditPatient} 
                    id={patientId} 
                    onClick={e => closeEditPatient(e)} 
                    updateStatus={e => updateStatus(e)}
                />
            : null}

           <div style={{display: 'none'}}>
           <div ref={componentRef}>
                {data.length ? data.map((item, key) => {
                        return (
                            <div style={{height: "770px", marginLeft: "50px", marginTop: "20px"}} key={key}>
                                <h1> {item.name_surname} </h1>

                                <p style={{fontSize: "18px"}}>
                                    Հասակ - {item.height ? item.height + " սմ" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    Քաշ - {item.weight ? item.weight + " կգ" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    Ջերմաստիճան - {item.temperature ? item.temperature + " c°" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    Գլյուկոզա - {item.glucose ? item.glucose + " մմլ/լ" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    Զարկերակի ճնշ. - {item.pressure ? item.pressure + " մմ/սա" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    Պուլս - {item.pulse ? item.pulse + " զ/ր" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    SpO2 - {item.spo ? item.spo + "%" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    FDG տրվեց - {item.fdg_mbq ? item.fdg_mbq + " MBq / " + item.fdg_hour + " ժամ" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    Ներարկվել է - {item.inject_mbq ? item.inject_mbq + " MBq / " + item.inject_hour + " ժամ" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    Մնացորդ - {item.balance ? item.balance + " MBq" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    FDG ակտ. - {item.fdg_active ? item.fdg_active + " MBq" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    Դուրս գրում - {item.write_out_msv ? item.write_out_msv + " mSv / " + item.write_out_hour + " ժամ" : ""}
                                </p>

                                <p style={{fontSize: "18px"}}>
                                    Նշումներ - {item.notes ? item.notes : ""}
                                </p>
                            </div>
                        )
                    })
                : null}
            </div>
           </div>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ArrowBackIcon onClick={() => history.push("/pet")} />

                    <Typography variant="h5" gutterBottom>Հետազոտվողներ - {data.length}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<LibraryAddIcon />}
                        onClick={() => setIsModalOpen(true)}
                    >
                        Ավելացնել
                    </Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} style={{ marginTop: "30px" }}>
                {isDeleteSuccess === false ? <Alert variant="filled" severity="error">Տվյալը չի ջնջվել - փորձեք կրկին</Alert> :
                isDeleteSuccess === true ? <Alert variant="filled" severity="success">Տվյալը ջնջվել է հաջողությամբ</Alert> : null}

                {isDataAdded ? <Alert variant="filled" severity="success">Ավելացումը կատարվել է հաջողությանբ</Alert> : null}

                {isUpdateSuccess ? <Alert variant="filled" severity="success">Տվյալը փոփոխվել է հաջողությանբ</Alert> : null}

                <Table style={{width: "100%"}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Tooltip title="Տպել տվյալնները" aria-label="fileUpload">
                                    <ReactToPrint
                                        trigger={() => <PrintIcon style={{ cursor: "pointer" }} />}
                                        content={() => componentRef.current}
                                    />
                                </Tooltip>
                            </TableCell>
                            <TableCell>Անուն Ազգանուն</TableCell>
                            <TableCell>Ծննդյան օր/ամիս/տարի</TableCell>
                            <TableCell>
                                <Tooltip title="Թարմացնել տվյալնները" aria-label="refreshdata">
                                    <RefreshIcon style={{cursor: "pointer"}} onClick={refreshData} />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {!isDataLoaded ? <TableRow><TableCell><CircularProgress /></TableCell></TableRow> : printData(data)}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}