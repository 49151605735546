import React from "react";
import clsx from "clsx";
import { fade, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MainListItems from "./MainListItems";

//Icons
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";

//Search
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

// CoreData imports
import Orders from "./coreData/Orders";
import Create from "./coreData/Create";
import Edit from "./coreData/Edit";
import More from "./coreData/More";

// Email imports
import Email from "./emails/Email";
import EmailCreate from "./emails/Create";

// Archive
import Archive from "./Archive/Archive";

// Deleted
import Deleted from "./DeleteItems/Deleted";

//Search
import Search from "./coreData/Search";

//Phone
import Phone from './Phone/Phone';

//Ct
import Ct from './Ct/Ct';
import CtCreate from './Ct/CtCreate';
import CtSingle from './Ct/CtSingle';
import CtEdit from './Ct/CtEdit';

//Spect
import SpectCt from './Spect/Spect';
import SpectCtCreate from './Spect/SpectCtCreate';
import SpectSingle from './Spect/SpectSingle';
import SpectCtEdit from './Spect/SpectCtEdit';

import Pet from './pet/Pet';
import PatientMore from './pet/PatientMore';

//Report
import Report from './Report/Report';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Copyright from "./Copyright";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [searchBarOpen, setSearchBarOpen] = React.useState(false);
  const [searchData, setSearchData] = React.useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  fetch(`${process.env.REACT_APP_API_URL}/auth/checkUser`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("appname_token"),
    },
  })
    .then(res => res.json())
    .then(res => {
      if (res.message === "Unauthenticated.") {
        localStorage.removeItem("appname_token");
        window.location.href = "/";
      }

      if (res.status === "Token is Invalid") {
        localStorage.removeItem("appname_token");
        window.location.href = "/";
      }
    })
    .catch(err => console.log(err));

  const submitSearch = e => {
    e.preventDefault();

    if (document.getElementById("searchInput").value.trim()) {
      fetch(`${process.env.REACT_APP_API_URL}/search`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("appname_token"),
        },
        body: JSON.stringify({
          search: document.getElementById("searchInput").value,
        }),
      })
        .then(res => res.json())
        .then(res => {
          setSearchBarOpen(true);
          setSearchData(res);
        })
        .catch(err => console.error(err));
    }
  };

  const openUploadForm = val => {
    setSearchBarOpen(val);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      {searchBarOpen ? (
        <Search openForm={openUploadForm} searchData={searchData} />
      ) : null}

      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Pet/Ct Armenia
          </Typography>

          <div className={classes.search}>
            <form onSubmit={submitSearch}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Որոնել..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                id="searchInput"
                endAdornment={
                  <CloseIcon
                    onClick={() =>
                      (document.getElementById("searchInput").value = "")
                    }
                    style={{
                      cursor: "pointer",
                      fontSize: "17px",
                      color: "#000",
                    }}
                  />
                }
              />
            </form>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <List>
          <MainListItems />
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Router>
              <Switch>
                {/* Recent Orders */}
                <Route path="/" exact component={Orders} />

                {/* Create component */}
                <Route path="/create" component={Create} />

                {/* Edit component */}
                <Route path="/edit/:itemId" component={Edit} />

                {/* Email component */}
                <Route path="/email" exact component={Email} />

                {/* Email component */}
                <Route path="/email/create" component={EmailCreate} />

                {/* More component */}
                <Route path="/more/:itemId" component={More} />

                {/* Archive */}
                <Route path="/archive" component={Archive} />

                {/* Delete */}
                <Route path="/deleted" component={Deleted} />

                {/* {Phone} */}
                <Route path="/phone" component={Phone} />

                {/* {Pet} */}
                <Route path="/pet" component={Pet} />

                {/* {Pet Single} */}
                <Route path="/pet-list/:itemId" component={PatientMore} />

                {/* {Ct examination} */}
                <Route path="/ct-patients" component={Ct} />

                {/* {Ct examination} */}
                <Route path="/ct-patients-create" component={CtCreate} />

                {/* {Ct Single examination} */}
                <Route path="/ct-patients-single/:id" component={CtSingle} />

                {/* { Ct Edit } */}
                <Route path="/ct-patients-edit/:id" component={CtEdit} />

                {/* { Spect Ct } */}
                <Route path="/spect-patients" component={SpectCt} />
                <Route path="/spect-patient/:id" component={SpectSingle} />
                <Route path="/spect-create" component={SpectCtCreate} />
                <Route path="/spect-edit/:id" component={SpectCtEdit} />

                {/* { Reports } */}
                <Route path="/report" component={Report} />
                
              </Switch>
            </Router>
          </Grid>

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
