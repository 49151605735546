import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Typography from '@material-ui/core/Typography';

//Icons
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from "@material-ui/core/CircularProgress";

import Tooltip from '@material-ui/core/Tooltip';

import Alert from '@material-ui/lab/Alert';

//Import Icons
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';

import dateCheck from "../usefulFunction/DateCheck";

function SpectCt() {
    const history = useHistory();

    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/spect`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("appname_token"),
            },
        })
            .then(res => res.json())
            .then(res => {
                setIsLoaded(true);

                setData(res);
            })
            .catch(err => console.log(err));
    }, []);

    const getApiData = apiData => {
        return !apiData.length ? (
            <TableRow key={1}>
                <TableCell>Տվյալններ չկա</TableCell>
            </TableRow>
        ) : (
            apiData.map((row, key) => {
                return (
                    <TableRow key={key} style={{ backgroundColor: row.diabetes ? "rgb(255, 152, 0)" : "white" }}>
                        <TableCell>{row.name_surname}</TableCell>
                        <TableCell>{dateCheck(row.first_day)}</TableCell>
                        <TableCell>{row.type_of_disease}</TableCell>
                        <TableCell>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => history.push(`/spect-patient/${row.id}`)}
                                startIcon={<VisibilityIcon />}
                            >
                                Ավելին
                            </Button>
                        </TableCell>
                    </TableRow>
                );
            })
        );
    }

    const refreshAllData = () => {
        setIsLoaded(false);

        fetch(`${process.env.REACT_APP_API_URL}/spect`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("appname_token"),
            },
        })
            .then(res => res.json())
            .then(res => {
                setIsLoaded(true);

                setData(res);
            })
            .catch(err => console.log(err));
    }

    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom>
                SPECT / CT
            </Typography>

            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: "10px" }}
                    startIcon={<AddIcon />}
                    onClick={() => history.push("/spect-create")}
                >
                    Ավելացնել
                </Button>

                <Paper>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <strong>Անուն Ազգանուն</strong>
                                </TableCell>

                                <TableCell>
                                    <strong>Հետազոտության նախնական օրը</strong>
                                </TableCell>

                                <TableCell>
                                    <strong>Հիվանդության տեսակ</strong>
                                </TableCell>

                                <TableCell>
                                    <Tooltip title="Թարմացնել տվյալնները" aria-label="refreshdata">
                                        <RefreshIcon style={{cursor: "pointer"}} onClick={refreshAllData} />
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {!isLoaded ? (
                                <TableRow key={1}>
                                    <TableCell>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                getApiData(data)
                            )}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        </React.Fragment>
    )
}

export default SpectCt;