function dateFormat (date, yearShow) {
    if (date === '-') {
        return date;
    }

    var arr = date.split('-');
    var month = arr[1];
    var year = arr[0];

    if (month === '01') {
        month = "Հունվար";
    } else if (month === '02') {
        month = "Փետրվար";
    } else if (month === '03') {
        month = "Մարտ";
    } else if (month === '04') {
        month = "Ապրիլ";
    } else if (month === '05') {
        month = "Մայիս";
    } else if (month === '06') {
        month = "Հունիս";
    } else if (month === '07') {
        month = "Հուլիս";
    } else if (month === '08') {
        month = "Օգոստոս";
    } else if (month === '09') {
        month = "Սեպտեմբեր";
    } else if (month === '10') {
        month = "Հոկտեմբեր";
    } else if (month === '11') {
        month = "Նոյեմբեր";
    } else if (month === '12') {
        month = "Դեկտեմբեր";
    }

    if (yearShow === 'no') {
        return arr[2] + ' ' + month;
    } else {
        return arr[2] + ' ' + month + ', ' + year;
    }
}

export default dateFormat;