import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import BackupIcon from '@material-ui/icons/Backup';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';

class Create extends React.Component {
    constructor () {
        super();

        this.state = {
            selectedFile: "",
            emailData: "",
            emailError: "",
            fileError: "",
            isLoaded: false,
            successCheck: false
        }

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.checkSuccess = this.checkSuccess.bind(this);
    }

    onFormSubmit (e) {
        e.preventDefault();

        this.setState({isLoaded: true});

        let formData = new FormData();
        formData.append('customFileName', this.state.selectedFile.name === undefined ? "" : this.state.selectedFile);
        formData.append('email', this.state.emailData);

        fetch(`${process.env.REACT_APP_API_URL}/upload`, {
            method: 'POST',
            body: formData
        })
        .then(res => res.json())
        .then(data => {
            if (data.errors) {
                if (data.errors.customFileName) {
                    this.setState({fileError: data.errors.customFileName[0]});
                } else {
                    this.setState({fileError: ""});
                }
    
                if (data.errors.email) {
                    this.setState({emailError: data.errors.email[0]});
                } else {
                    this.setState({emailError: ""});
                }
            } else {
                if (data[0] === "Ավելացումը կատարվել է հաջողությանբ") {
                    this.setState({emailError: "", selectedFile: "", fileError: "", emailData: "", successCheck: true});
                }
            }
            
            this.setState({isLoaded: false});
        })
    }

    checkSuccess () {
        if (!this.state.successCheck) {
            return null;
        } else {
            setTimeout(function () {
                this.setState({successCheck: false});
            }.bind(this), 3000);

            return (
                <Button variant="contained" color="secondary" style={{backgroundColor: "green"}} startIcon={<CheckIcon />}>
                    Ավելացումը կատարվել է հաջողությանբ
                </Button>
            );
        }
    }

    render () {
        return (
            <React.Fragment>

            {this.checkSuccess()}

            <form onSubmit={this.onFormSubmit} style={{width: "100%"}} encType="multipart/form-data">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="email"
                            label="Էլ հասցե *"
                            fullWidth
                            autoComplete="given-email"
                            value={this.state.emailData}
                            onInput={e => this.setState({emailData: e.target.value})}
                        />

                    {this.state.emailError ? <FormHelperText error>{this.state.emailError}</FormHelperText> : ""}
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <input type="file" id="file_inp" style={{display: "none"}} 
                        onChange={e => this.setState({selectedFile: e.target.files[0]})}
                        name="customFileName" />

                        <label htmlFor={'file_inp'}>
                            <Button
                                fullWidth
                                variant="contained"
                                component="span"
                                startIcon={<BackupIcon />}
                            >
                            {!this.state.selectedFile ? "Ընտրել ֆայլը *" : this.state.selectedFile.name}
                            </Button>
                        </label>

                        {this.state.fileError ? <FormHelperText error>{this.state.fileError}</FormHelperText> : ""}
                    </Grid>

                    <Grid item xs={12}>
                        {this.state.isLoaded ? <CircularProgress /> : <Button variant="contained" type="submit" color="primary">Հաստատել</Button>}
                    </Grid>
                </Grid>
            </form>
            </React.Fragment>
        )
    }
}

export default Create;