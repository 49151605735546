import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import 'date-fns';
import { format, isValid } from 'date-fns';
import RuLocalizedUtils from '../coreData/RuLocalizedUtils';
import ruLocale from "date-fns/locale/ru";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function EditPatient(props) {
    const [nameSurname, setNameSurname]= useState(null);
    const [birthday, setBirthday]= useState(null);
    const [height, setHeight]= useState('');
    const [weight, setWeight]= useState('');
    const [temperature, setTemperature]= useState('');
    const [glucose, setGlucose]= useState('');
    const [pressure, setPressure]= useState('');
    const [pulse, setPulse]= useState('');
    const [spo, setSpo]= useState('');
    const [fdgMbq, setFdgMbq]= useState('');
    const [fdgHour, setFdgHour]= useState('');
    const [injectMbq, setInjectMbq]= useState('');
    const [injectHour, setInjectHour]= useState('');
    const [balance, setBalance]= useState('');
    const [fdgActive, setFdgActive]= useState('');
    const [writeOutMsv, setWriteOutMsv]= useState('');
    const [writeOutHour, setWriteOutHour]= useState('');
    const [notes, setNotes]= useState('');
    const [number, setNumber] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const [btnDisabled, setBtnDisabled]= useState(false);

    const numberRef = useRef(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/patient/${props.id}`, {
            method: "GET",
            headers: {"Content-type": "application/json; charset=UTF-8", "Authorization": "Bearer " + localStorage.getItem("appname_token")}
        })
        .then(response => response.json()) 
        .then(json => {
            setNameSurname(json.name_surname)
            setBirthday(json.birthday)
            setHeight(json.height)
            setWeight(json.weight)
            setTemperature(json.temperature)
            setGlucose(json.glucose)
            setPressure(json.pressure)

            setPulse(json.pulse)
            setSpo(json.spo)
            setFdgMbq(json.fdg_mbq)
            setFdgHour(json.fdg_hour)
            setInjectMbq(json.inject_mbq)
            setInjectHour(json.inject_hour)

            setBalance(json.balance)
            setFdgActive(json.fdg_active)
            setWriteOutMsv(json.write_out_msv)
            setWriteOutHour(json.write_out_hour)
            setNotes(json.notes)

            setNumber(json.number);

            setIsDataLoaded(true)
        }).catch(err => {
           console.log(err)
        })
    }, [1])

    const update = () => {
        const obj = {
            'name_surname': nameSurname,
            'birthday': isValid(birthday) ? format(birthday, 'yyyy-MM-dd') : birthday,
            'height': height,
            'weight': weight,
            'temperature': temperature,
            'glucose': glucose,
            'pressure': pressure,
            'pulse': pulse,
            'spo': spo,
            'fdg_mbq': fdgMbq,
            'fdg_hour': fdgHour,
            'inject_mbq': injectMbq,
            'inject_hour': injectHour,
            'balance': balance,
            'fdg_active': fdgActive,
            'write_out_msv': writeOutMsv,
            'write_out_hour': writeOutHour,
            'notes': notes,
            'number': numberRef.current.value
        };

        setBtnDisabled(true);

        fetch(`${process.env.REACT_APP_API_URL}/update_patient/${props.id}`, {
            method: "POST",
            body: JSON.stringify({data: obj}),
            headers: {
                "Content-type": "application/json; charset=UTF-8", 
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json()) 
        .then(json => {
            props.updateStatus(json.status);
            setBtnDisabled(false);
        }).catch(err => {
           console.log(err)

           setBtnDisabled(false);
        })
    }

    const handleClose = () => {
        if (window.confirm("Փակել?")) {
            props.onClick({ status: false });
        }
    };

    return (
        <Dialog open={props.isOpen} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Փոխել</DialogTitle>

            <DialogContent>
                {!isDataLoaded ? <CircularProgress /> : <React.Fragment>

                <TextField
                    autoFocus
                    margin="dense"
                    label="Թիվ"
                    type="number"
                    defaultValue={number}
                    inputRef={numberRef}
                    fullWidth
                />

                <TextField
                    autoFocus
                    margin="dense"
                    label="Անուն Ազգանուն"
                    type="text"
                    value={nameSurname === null ? "" : nameSurname}
                    onChange={e => setNameSurname(e.target.value)}
                    fullWidth
                />

                <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
                    <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        format="MM/dd/yyyy"
                        cancelLabel={"отмена"}
                        okLabel={"Хорошо"}
                        placeholder="ամիս/օր/տարի"
                        value={birthday}
                        onChange={e => setBirthday(e)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>

                <TextField
                    margin="dense"
                    label="Հասակ"
                    value={height === null ? "" : height}
                    onChange={e => setHeight(e.target.value)}
                    fullWidth
                />

                <TextField
                    margin="dense"
                    label="Քաշ"
                    value={weight === null ? "" : weight}
                    onChange={e => setWeight(e.target.value)}
                    fullWidth
                />

                <TextField
                    margin="dense"
                    label="Ջերմություն"
                    value={temperature === null ? "" : temperature}
                    onChange={e => setTemperature(e.target.value)}
                    fullWidth
                />

                <TextField
                    margin="dense"
                    label="Գլյուկոզա"
                    value={glucose === null ? "" : glucose}
                    onChange={e => setGlucose(e.target.value)}
                    fullWidth
                />

                <TextField
                    margin="dense"
                    label="Զարկ./ճ."
                    value={pressure === null ? "" : pressure}
                    onChange={e => setPressure(e.target.value)}
                    fullWidth
                />

                <TextField
                    margin="dense"
                    label="Պուլս"
                    value={pulse === null ? "" : pulse}
                    onChange={e => setPulse(e.target.value)}
                    fullWidth
                />

                <TextField
                    margin="dense"
                    label="SpO2"
                    value={spo === null ? "" : spo}
                    onChange={e => setSpo(e.target.value)}
                    fullWidth
                />

                <p>FDG տրվեց</p>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="MBq"
                            value={fdgMbq === null ? "" : fdgMbq}
                            onChange={e => setFdgMbq(e.target.value)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="ժամ"
                            value={fdgHour === null ? "" : fdgHour}
                            onChange={e => setFdgHour(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <p>Ներարկվել է</p>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="MBq"
                            value={injectMbq === null ? "" : injectMbq}
                            onChange={e => setInjectMbq(e.target.value)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="ժամ"
                            value={injectHour === null ? "" : injectHour}
                            onChange={e => setInjectHour(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <TextField
                    margin="dense"
                    label="Մնացորդ"
                    value={balance === null ? "" : balance}
                    onChange={e => setBalance(e.target.value)}
                    fullWidth
                />

                <TextField
                    margin="dense"
                    label="FDG ակտ"
                    value={fdgActive === null ? "" : fdgActive}
                    onChange={e => setFdgActive(e.target.value)}
                    fullWidth
                />

                <p>Դուրս Գրում</p>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="mSv"
                            value={writeOutMsv === null ? "" : writeOutMsv}
                            onChange={e => setWriteOutMsv(e.target.value)}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="ժամ"
                            value={writeOutHour === null ? "" : writeOutHour}
                            onChange={e => setWriteOutHour(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <TextField
                    margin="dense"
                    label="Նշումներ"
                    value={notes === null ? "" : notes}
                    multiline
                    rows={4}
                    onChange={e => setNotes(e.target.value)}
                    fullWidth
                />
                </React.Fragment>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={btnDisabled}>Փակել</Button>

                <Button onClick={update} color="primary" disabled={btnDisabled}>Հաստատել</Button>
            </DialogActions>
        </Dialog>
    );
}