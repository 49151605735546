import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { Typography } from "@material-ui/core";

export default function OtherInformation() {
  const [open, setOpen] = useState(false);
  const [dataCount, setDataCount] = useState(null);
  const [finishedCount, setFinishedCount] = useState(null);
  const [rejectedCount, setRejectedCount] = useState(null);
  const [notFinishedCount, setNotFinishedCount] = useState(null);
  const [stateOrderCount, setStateOrderCount] = useState(null);

  const handleClickOpen = () => {
    fetch(`${process.env.REACT_APP_API_URL}/another`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("appname_token"),
      },
    })
      .then(res => res.json())
      .then(res => {
        setStateOrderCount(res.stateOrderCount);
        setDataCount(res.dataCount);
        setFinishedCount(res.archiveCount);
        setNotFinishedCount(res.noArchivehedCount);
        setRejectedCount(res.rejectedCount);
      })
      .catch(err => {
        console.log(err);
      });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ListItem button>
        <ListItemIcon>
          <FormatListNumberedIcon />
        </ListItemIcon>
        <ListItemText onClick={handleClickOpen} primary="Այլ տվյալններ" />
      </ListItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Այլ տվյալններ"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle1" component={"span"} display="block">
              Ընդհանուր հիվանդների քանակ - {dataCount}
            </Typography>
            <Typography variant="subtitle1" component={"span"} display="block">
              Արխիվացված հիվանդների քանակ - {finishedCount}
            </Typography>
            <Typography variant="subtitle1" component={"span"} display="block">
              Չարխիվացված հիվանդների քանակ - {notFinishedCount}
            </Typography>
            <Typography variant="subtitle1" component={"span"} display="block">
              Պետպատվերների քանակ - {stateOrderCount}
            </Typography>
            <Typography variant="subtitle1" component={"span"}>
              Հրաժարված հիվանդների քանակ - {rejectedCount}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Փակել
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
