import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";

import dateCheck from "../usefulFunction/DateCheck";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import BackupIcon from "@material-ui/icons/Backup";

// import 'date-fns';
import CircularProgress from "@material-ui/core/CircularProgress";

//Upload js
import Upload from "../FileUpload/Upload";

//Tooltip
import Tooltip from '@material-ui/core/Tooltip';

import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12.4px",
      }
    }
  }
});

class Deleted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoaded: false,
      dataFilter: "",
      changeDateFilter: false,
      firstDateFilter: new Date(),
      takenNumber: 20,
      allDataCount: 0,
      checkAllSwitch: false,
      disableMoreLess: false,
      delteAllState: false,
      allDeletesIds: [],
      archiveLoader: false,
      archiveSuccess: false,
      uploadFormOpen: false,
      userId: 0,

      isDeletetForever: false,
      deletedMessage: "",
    };

    this.restoreFromTrash = this.restoreFromTrash.bind(this);
    this.deleteForever = this.deleteForever.bind(this);
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/deleted-items`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("appname_token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        res.map((item) => {
          return (item.print = false);
        });

        this.setState({ data: res, isLoaded: true, allDataCount: res.count });
      })
      .catch((err) => console.log(err));
  }

  restoreFromTrash(id) {
    this.setState({ isLoaded: false });

    fetch(`${process.env.REACT_APP_API_URL}/restore-trash/${id}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("appname_token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data: data, isLoaded: true });
      })
      .catch((err) => console.log(err));
  }

  deleteForever(id) {
    let conf = window.confirm("Համոզված եք որ ուզում եք ջնջել");

    if (conf) {
      this.setState({ isLoaded: false });

      fetch(`${process.env.REACT_APP_API_URL}/data/${id}`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("appname_token"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "Success") {
            let that = this;

            this.setState({
              isDeletetForever: true,
              data: data.data,
              isLoaded: true,
              deletedMessage: "Տվյալը ջնջվել է հաջողությամբ",
            });

            window.setTimeout(function () {
              that.setState({ isDeletetForever: false });
            }, 2000);
          } else {
            let that = this;

            this.setState({
              isDeletetForever: true,
              deletedMessage: "Փորձեք կրկին",
            });

            window.setTimeout(function () {
              that.setState({ isDeletetForever: false });
            }, 2000);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  setData(data) {
    return !data.length ? (
      <TableRow key={1}>
        <TableCell>Տվյալններ չկա</TableCell>
      </TableRow>
    ) : (
      data.map(row => {
        return (
          <MuiThemeProvider key={row.id} theme={theme}>
            <TableRow style={{ backgroundColor: row.finished ? "#8fd19e" : "white" }}>
              <TableCell>{row.name_surname}</TableCell>
              <TableCell>{dateCheck(row.first_day)}</TableCell>
              <TableCell>{row.type_of_disease}</TableCell>

              <TableCell>
                {this.state.archiveLoader ? (
                  <CircularProgress size={25} />
                ) : (
                  <Tooltip title="Հանել ջնջվածների ցուցակից" aria-label="RestoreFromTrashIcon">
                    <RestoreFromTrashIcon
                      cursor="pointer"
                      onClick={() => this.restoreFromTrash(row.id)}
                    />
                  </Tooltip>
                )}
              </TableCell>

              <TableCell>
                <Tooltip title="Կցել ֆայլեր">
                  <BackupIcon
                    onClick={() =>
                      this.setState({
                        uploadFormOpen: true,
                        userId: row.id,
                      })
                    }
                    cursor="pointer"
                  />
                </Tooltip>
              </TableCell>

              <TableCell>
                <Tooltip title="Ջնջել անվերադարձ" aria-label="refreshdata">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => this.deleteForever(row.id)}
                  >
                    Ջնջել
                  </Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          </MuiThemeProvider>
        );
      })
    );
  }

  openUploadForm = (val) => {
    this.setState({ uploadFormOpen: val });
  };

  render() {
    return (
      <div>
        {!this.state.isDeletetForever ? (
          ""
        ) : (
          <Button
            variant="contained"
            color={
              this.state.deletedMessage === "Փորձեք կրկին"
                ? "secondary"
                : "primary"
            }
          >
            {this.state.deletedMessage}
          </Button>
        )}

        {this.state.uploadFormOpen ? (
          <Upload openForm={this.openUploadForm} userId={this.state.userId} />
        ) : null}

        <Paper style={{ marginTop: "10px" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Անուն Ազգանուն</strong>
                </TableCell>
                <TableCell>
                  <strong>Հետազոտության նախնական օրը</strong>
                </TableCell>
                <TableCell>
                  <strong>Հիվանդության տեսակ</strong>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!this.state.isLoaded ? (
                <TableRow key={1}>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                this.setData(this.state.data)
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default Deleted;
