import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

//Print js
import print from "print-js";

function PetCtReport () {
    const [petCtYear, setPetCtYear] = useState(new Date().getFullYear());

    const [petCtMonth, setPetCtMonth] = useState("all");

    const [petYearLoad, setPetYearLoad] = useState(false);

    const [petCtData, setPetCtData] = useState(null);

    const [petAllDataCherityCount, setPetAllDataCherityCount] = useState(0)

    const [finishedCount, setFinishedCount] = useState(null);

    const [rejectedCount, setRejectedCount] = useState(null);

    const [stateOrderCount, setStateOrderCount] = useState(null)

    const [notFinishedCount, setNotFinishedCount] = useState(null);

    const [dataCount, setDataCount] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("user_name") !== "Director") {
            window.location.href = "/";
        }

        fetch(`${process.env.REACT_APP_API_URL}/another`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("appname_token"),
            },
        })
        .then(res => res.json())
        .then(res => {
            setPetAllDataCherityCount(res.charityCount);
            setDataCount(res.dataCount);
            setFinishedCount(res.archiveCount);
            setNotFinishedCount(res.noArchivehedCount);
            setRejectedCount(res.rejectedCount);
            setStateOrderCount(res.stateOrderCount)
        })
        .catch(err => console.log(err))
    }, [])

    const formatMoney = n => (Math.round(n * 100) / 100).toLocaleString();

    const formatDateArm = month => {
        month =  "" + month + "";
        
        if (month === '1') {
            month = "Հունվար";
        } else if (month === '2') {
            month = "Փետրվար";
        } else if (month === '3') {
            month = "Մարտ";
        } else if (month === '4') {
            month = "Ապրիլ";
        } else if (month === '5') {
            month = "Մայիս";
        } else if (month === '6') {
            month = "Հունիս";
        } else if (month === '7') {
            month = "Հուլիս";
        } else if (month === '8') {
            month = "Օգոստոս";
        } else if (month === '9') {
            month = "Սեպտեմբեր";
        } else if (month === '10') {
            month = "Հոկտեմբեր";
        } else if (month === '11') {
            month = "Նոյեմբեր";
        } else if (month === '12') {
            month = "Դեկտեմբեր";
        }

        return month;
    }

    const petctYearFunc = () => {
        setPetYearLoad(true)

        fetch(`${process.env.REACT_APP_API_URL}/report-petct-year`, {
            method: "POST",
            body: JSON.stringify({year: petCtYear, month: petCtMonth}),
            headers: {
                "Content-type": "application/json; charset=UTF-8", 
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json()) 
        .then(json => {
            setPetYearLoad(false)
            setPetCtData(json)
        }).catch(err => console.log(err))
    }

    const pdfGenerate = () => {
        const fileName = `Հաշվետվություն_PET/CT_${petCtMonth !== "all" ? formatDateArm(petCtMonth) + "_" + petCtYear : petCtYear}`;

        fetch(`${process.env.REACT_APP_API_URL}/report-petct-year`, {
            method: "POST",
            body: JSON.stringify({year: petCtYear, month: petCtMonth}),
            headers: {
                "Content-type": "application/json; charset=UTF-8", 
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json()) 
        .then(json => {
            document.title = fileName;

            json.total = formatMoney((json.archiveCount - json.charityCount) * 450000)

            json = [json];

            print({
              printable: json,
              header: `PET/CT Հաշվետվություն - ${petCtMonth !== "all" ? formatDateArm(petCtMonth) + ", " + petCtYear : petCtYear}`,
              headerStyle: "text-align: center",
              properties: [
                { field: "dataCount", displayName: "Ընդհանուր հիվանդների քանակ" },
                { field: "archiveCount", displayName: "Արխիվացված հիվանդների քանակ" },
                { field: "noArchivehedCount", displayName: "Չարխիվացված հիվանդների քանակ" },
                { field: "rejectedCount", displayName: "Հրաժարված հիվանդների քանակ" },
                { field: "charityCount", displayName: "Բարեգործությունների քանակ" },
                { field: "stateOrderCount", displayName: "Պետպատվերների քանակ" },
                { field: "total", displayName: "Ընդհանուր գումար" }
              ],
              documentTitle: "«Ռադիոիզոտոպների արտադրության կենտրոն» ՓԲԸ",
              type: "json",
              style: "table tr td {padding: 17px}",
              onPrintDialogClose: () => {
                document.title = "Registration Program";
              }
            });
        }).catch(err => console.log(err))
    }

    return (
        <Paper style={{width: "100%", padding: "20px", marginTop: "20px"}}>
                <Typography variant="h6">PET/CT հետազոտության հաշվետվություն</Typography>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <TextField
                                label="Տարի"
                                defaultValue={new Date().getFullYear()}
                                fullWidth
                                onChange={e => setPetCtYear(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <InputLabel>Ամիս</InputLabel>

                            <Box>
                                <TextField select value={petCtMonth} onChange={e => setPetCtMonth(e.target.value)} fullWidth>
                                    <MenuItem value={"all"}>Բոլոր ամիսներ</MenuItem>
                                    <MenuItem value={1}>Հունվար</MenuItem>
                                    <MenuItem value={2}>Փետրվար</MenuItem>
                                    <MenuItem value={3}>Մարտ</MenuItem>
                                    <MenuItem value={4}>Ապրիլ</MenuItem>
                                    <MenuItem value={5}>Մայիս</MenuItem>
                                    <MenuItem value={6}>Հունիս</MenuItem>
                                    <MenuItem value={7}>Հուլիս</MenuItem>
                                    <MenuItem value={8}>Օգոստոս</MenuItem>
                                    <MenuItem value={9}>Սեպտեմբեր</MenuItem>
                                    <MenuItem value={10}>Հոկտեմբեր</MenuItem>
                                    <MenuItem value={11}>Նոյեմբեր</MenuItem>
                                    <MenuItem value={12}>Դեկտեմբեր</MenuItem>
                                </TextField>
                            </Box>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            {!petYearLoad ? <Button variant="contained" color="primary" onClick={petctYearFunc}>
                                Ցուցադրել
                            </Button> : <CircularProgress />}
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Ընդհանուր հիվանդների քանակ - {petCtData ? petCtData.dataCount : 0}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Արխիվացված հիվանդների քանակ - {petCtData ? petCtData.archiveCount : 0}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Չարխիվացված հիվանդների քանակ - {petCtData ? petCtData.noArchivehedCount : 0}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Հրաժարված հիվանդների քանակ - {petCtData ? petCtData.rejectedCount : 0}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Բարեգործությունների քանակ - {petCtData ? petCtData.charityCount : 0}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Պետպատվերների քանակ - {petCtData ? petCtData.stateOrderCount : 0}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Ընդհանուր գումար - {petCtData ? formatMoney((petCtData.archiveCount - petCtData.charityCount) * 450000) : 0} դրամ</p>
                        </Grid>

                        <Grid>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<PictureAsPdfIcon />}
                                onClick={() => pdfGenerate()}
                            >
                                Ներբեռնել PDF
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Ընդհանուր հիվանդների քանակ - {dataCount}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Արխիվացված հիվանդների քանակ - {finishedCount}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Չարխիվացված հիվանդների քանակ - {notFinishedCount}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Բարեգործությունների քանակ - {petAllDataCherityCount}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Պետպատվերների քանակ - {stateOrderCount}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Հրաժարված հիվանդների քանակ - {rejectedCount}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Ընդհանուր գումար - {finishedCount !== null ? formatMoney((finishedCount - petAllDataCherityCount) * 450000) : finishedCount} դրամ</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
    )
}

export default PetCtReport;