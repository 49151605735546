import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

class Phone extends React.Component {
  constructor() {
    super();

    this.state = {
      data: [],
      isLoaded: false,
      dialogOpen: false,
      emailsCollection: null,
      checkAllState: false,
      phoneSum: 0,
      filterBtn: "all",
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/all-phones`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("appname_token"),
      },
    })
    .then(res => res.json())
    .then(res => {      
      var that = this;
      
      let filterLetter = res.filter(item => {
        if (/^[0-9,]*$/.test(item.phone)) {          
          return item;
        }
      });
      
      filterLetter.map(function (item) {
        if (item.phone.includes(",")) {
          item.phone.split(",").map(function (newItem) {
            if (newItem.trim().length <= 9) {
              that.setState({data: [...that.state.data, {
                id: item.id,
                name_surname: item.name_surname,
                phone: newItem.trim()
              }]})
            }
          })
        } else {
          that.setState({data: [...that.state.data, item]})
        }
      })

      this.setState({isLoaded: true, phoneSum: this.state.data.length})
    }).catch(err => console.log(err));
  }

  render() {
    const setData = data => {
      return !data.length ? (
        <TableRow key={1}>
          <TableCell>Տվյալններ չկա</TableCell>
        </TableRow>
      ) : (
        data.map((row, key) => (
          row.phone !== "" ? <TableRow
            key={key}
            style={{
              backgroundColor: row.checked ? "rgb(224 224 224 / 29%)" : "#fff",
            }}
          >
            <TableCell>{row.name_surname}</TableCell>
            <TableCell>{row.phone}</TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                href={row.exam_type === "pet" ? `/more/${row.id}` : row.exam_type === "spect" ? `/spect-patient/${row.id}` : `/ct-patients-single/${row.id}`}
                target="_blank"
              >
                Ավելին
              </Button>
            </TableCell>
          </TableRow> : null
        ))
      )
    }

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Անուն Ազգանուն</strong>
                  </TableCell>

                  <TableCell>
                    <strong>Հեռախոսահամար</strong>
                  </TableCell>

                  <TableCell>
                    <strong>{this.state.phoneSum} համար</strong>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.state.isLoaded ? (
                  setData(this.state.data)
                ) : (
                  <TableRow key={1}>
                    <TableCell>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Phone;
