import React, { useEffect } from 'react';
import SignIn from './components/Auth/SignIn';
import Dashboard from './components/Dashboard';
import "./App.css";

export default function App () {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(process.env.REACT_APP_WEBSITE_URL + '/serviceworker.js')
        .then(reg => console.log("Success: ", reg.scope))
        .catch(err => console.log("Failure: ", err))
    })
  }

  return localStorage.getItem("appname_token") ? <Dashboard /> : <SignIn />
}