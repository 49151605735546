import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import 'date-fns';
import { format, isValid } from 'date-fns';
import RuLocalizedUtils from '../coreData/RuLocalizedUtils';
import ruLocale from "date-fns/locale/ru";
import LinearProgress from '@material-ui/core/LinearProgress';

import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

//Icons
import RepeatIcon from '@material-ui/icons/Repeat';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';

//Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withRouter } from "react-router";

class SpectCtEdit extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            itemId: props.match.params.itemId,
            isLoadedData: false,
            nameSurnameValue: "",
            firstDayValue: "",
            doctorModal: false,
            oterTreatmentModal: false,
            oterTreatmentError: "",
            phoneValue: "",
            isLoadedButton: false,
            treatmentValue: "",
            visitsValue: "",
            successMessage: false,
            isLoadedDisable: false,
            
            //Clinic State
            clinic: "-",
            addClinic: "",
            addClinicError: "",
            addClinicModal: false,
            allClinics: ['<<Ֆանարջյան>> Կլինիկա', '<<Յոլյանի անվան արյունաբանական կենտրոն>>','<<Նաիրի>> ԲԿ','<<Միքայելյան վիրաբուժության ինստիտուտ>>','<<Էրեբունի>> ԲԿ','<<Հայ-Ամերիկյան առողջության կենտրոն>>','<<Աստղիկ>> ԲԿ',"-"],
            
            doctorName: "",
            
            fileName: "",
            fileDeleteError: "",
            progress: 0,
            fileNameError: "",
            isDeleteFile: false,
            uploadDisabled: false,

            errorMessage: false,

            diabetes: 0,

            spectPrice: [],

            priceValue: 0,

            stateOrder: "no",

            charity: "no",

            finalDay: null
        }

        this.firstDayChangeFunc = this.firstDayChangeFunc.bind(this);
        this.updateFunc = this.updateFunc.bind(this);
        this.deleteFirstDayValue = this.deleteFirstDayValue.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.addClinicChange = this.addClinicChange.bind(this);
        this.closeAddClinicModal = this.closeAddClinicModal.bind(this);
        this.fileDeleteFunc = this.fileDeleteFunc.bind(this)
    }

    componentDidMount () {
        fetch(`${process.env.REACT_APP_API_URL}/spect-edit/${this.props.match.params.id}`, {
            method: "GET",
            headers: {"Authorization": "Bearer " + localStorage.getItem("appname_token")}
        })
        .then(res => res.json())
        .then(res => {
            if (res.status === "Data not found") {
                window.location.href = "/spect-patients";
            }

            this.setState({
                nameSurnameValue: res.data.name_surname === "-" ? "" : res.data.name_surname, 
                firstDayValue: res.data.first_day.includes("/") ? null : res.data.first_day,
                firstDayMultOne: res.data.first_day.includes("/") ? res.data.first_day.split("/")[0] : null,
                firstDayMultTwo: res.data.first_day.includes("/") ? res.data.first_day.split("/")[1] : null,
                phoneValue: res.data.phone === "-" ? "" : res.data.phone,
                doctorName: res.data.doctor,
                typeOfDiseaseValue: res.data.type_of_disease === "-" ? "" : res.data.type_of_disease,
                weightValue: res.data.weight,
                emailValue: res.data.email,
                
                clinic: res.data.medical,
                allClinics: [...this.state.allClinics, res.data.medical],
                
                discValue: res.data.disc,
                documentsValue: res.data.documents,
                receivedValue: res.data.received,
                treatmentValue: res.data.type_of_treatment,
                visitsValue: res.data.visits,
                notesValue: res.data.notes,
                firstDayChange: res.data.first_day.includes("/") ? true : false,
                isLoadedData: true,
                fileName: res.data.file_name,
                diabetes: res.data.diabetes,
                spectPrice: res.data.spect_type,
                priceValue: res.data.spect_name,
                stateOrder: res.data.state_order,
                charity: res.data.charity,
                finalDay: res.data.final_day
            });
        }).catch(err => console.log(err))
    }
    
    firstDayChangeFunc () {
        if (!this.state.firstDayChange) {
            this.setState({firstDayChange: true});
        } else {
            this.setState({firstDayChange: false});   
        }
    }

    deleteFirstDayValue () {
        let prompt = window.confirm("Համոզված եք որ ուզում եք ջնջել");

        if (prompt) {
            if (!this.state.firstDayChange) {
                this.setState({firstDayValue: null});
            } else {
                this.setState({firstDayMultOne: null, firstDayMultTwo: null});
            }
        }
    }

    updateFunc () {
        let that = this;

        that.setState({isLoadedButton: true});

                let first_day = "";
                
                if (!that.state.firstDayChange) {
                    if (isValid(that.state.firstDayValue)) {
                        first_day = format(that.state.firstDayValue, 'yyyy-MM-dd');
                    } else {
                        first_day = that.state.firstDayValue;
                    }

                    if (that.state.firstDayValue === null || that.state.firstDayValue === "-") {
                        first_day = "-";
                    }
                } else {
                    let firstDayMultOneVar = "";
                    let firstDayMultTwoVar = "";

                    if (isValid(that.state.firstDayMultOne)) {
                        firstDayMultOneVar = format(that.state.firstDayMultOne, 'yyyy-MM-dd');
                    } else {
                        firstDayMultOneVar = that.state.firstDayMultOne;
                    }

                    if (isValid(that.state.firstDayMultTwo)) {
                        firstDayMultTwoVar = format(that.state.firstDayMultTwo, 'yyyy-MM-dd');
                    } else {
                        firstDayMultTwoVar = that.state.firstDayMultTwo;
                    }

                    first_day =  firstDayMultOneVar + "/" + firstDayMultTwoVar;
                }

                let obj = {
                    'name_surname': that.state.nameSurnameValue === "" ? "-" : that.state.nameSurnameValue,
                    'first_day': first_day,
                    'phone': that.state.phoneValue === "" ? "-" : that.state.phoneValue,
                    'medical': that.state.clinic,
                    'doctor': that.state.doctorName === "" ? "-" : that.state.doctorName,
                    'type_of_disease': that.state.typeOfDiseaseValue === "" ? "-" : that.state.typeOfDiseaseValue,
                    'weight': that.state.weightValue === "" ? "-" : that.state.weightValue,
                    'email': that.state.emailValue === "" ? "-" : that.state.emailValue,
                    'disc': that.state.discValue,
                    'documents': that.state.documentsValue,
                    'received': that.state.receivedValue,
                    'visits': that.state.visitsValue === "" ? 1 : that.state.visitsValue,
                    'notes': that.state.notesValue === "" ? "-" : that.state.notesValue,
                    'is_deleted': 0,
                    'file_name': that.state.fileName === "" ? "-" : that.state.fileName,
                    'diabetes': that.state.diabetes,
                    'user_name': localStorage.getItem("user_name"),
                    'spect_type': that.state.priceValue,
                    'state_order': that.state.stateOrder,
                    'charity': that.state.charity,
                    'final_day': isValid(that.state.finalDay) ? format(that.state.finalDay, 'yyyy-MM-dd') : that.state.finalDay
                }
                
                fetch(`${process.env.REACT_APP_API_URL}/spect-update/${this.props.match.params.id}`, {
                    method: "PUT",
                    body: JSON.stringify({data: obj}),
                    headers: {"Content-type": "application/json; charset=UTF-8", "Authorization": "Bearer " + localStorage.getItem("appname_token")}
                })
                .then(res => res.json()) 
                .then(json => {
                    that.setState({isLoadedDisable: true});

                    if (json.status === "Success") {
                        that.setState({successMessage: true});

                        setTimeout(() => {
                            that.setState({isLoadedDisable: false, isLoadedButton: false, successMessage: false});
                        }, 1500);
                    } else {
                        that.setState({errorMessage: true});

                        setTimeout(() => {
                            that.setState({isLoadedDisable: false, isLoadedButton: false, errorMessage: false});
                        }, 2000);
                    }
                })
                .catch(err => {
                    console.log(err);

                    that.setState({errorMessage: true});

                    setTimeout(() => {
                        that.setState({isLoadedDisable: false, isLoadedButton: false, errorMessage: false});
                    }, 2000);
                })
    }

    fileUpload (e) {
        let that = this;
        let data = new FormData();
        data.append('file', e.target.files[0]);
        data.append('exists_file', this.state.fileName);

        let request = new XMLHttpRequest();
        request.open('POST', `${process.env.REACT_APP_API_URL}/spect/edit-file-upload`);
        request.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("appname_token"));
        
        request.upload.addEventListener('progress', function(e) {
            let percent_complete = (e.loaded / e.total)*100;
            percent_complete < 100 ? that.setState({progress: percent_complete}) : that.setState({progress: 100});
        });
        
        request.addEventListener('load', function() {
            if (request.status === 200) {
                let res = JSON.parse(request.response);
                
                if (res.status === "Success") {
                    that.setState({fileName: res.name});
                } else {
                    that.setState({fileNameError: "error"});
                }
            }
            
            e.target.value = "";
        });
        
        request.send(data);
    }

    fileDeleteFunc () {
        this.setState({isDeleteFile: true});

        fetch(`${process.env.REACT_APP_API_URL}/spect/file-delete/${this.state.fileName}`, {
            method: 'DELETE',
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(res => {
            this.setState({isDeleteFile: false});

            if (res.status === "Success") {
                this.setState({fileDeleteError: "", fileName: "", progress: 0, uploadDisable: false});
            } else {
                this.setState({fileDeleteError: "error"})
            }
        }).catch(error => {
            this.setState({isDeleteFile: false})
            console.error(error);
        })
    }

    addClinicChange = e => {
        if (e.target.value) {
            this.setState({addClinicError: ""});
        }

        this.setState({addClinic: e.target.value});
    }

    closeAddClinicModal = param => {
        if (param) {
            if (!this.state.addClinic) {
                this.setState({addClinicError: "error"})
            } else {
                this.setState({addClinicModal: false, addClinicError: "", clinic: this.state.addClinic,
                allClinics: [...this.state.allClinics, this.state.addClinic]})
            }
        } else {
            if (this.state.addClinic) {
                let removeItem = this.state.allClinics.filter(item => {
                    return item !== this.state.addClinic;
                });
                
                this.setState({allClinics: removeItem, clinic: "-"});
            }

            this.setState({addClinicModal: false, addClinic: "", addClinicError: ""});
        }
    }

    render () {
        return (
            <div>
            <React.Fragment>
                {
                    !this.state.isLoadedData ? <CircularProgress /> :
                    
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <KeyboardBackspaceIcon 
                                onClick={() => this.props.history.push("/spect-patient/" + this.props.match.params.id)}
                                style={{cursor: "pointer"}}
                            />
                            {/* <a href={"/more/"+this.state.itemId}></a> */}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                label="Անուն Ազգանուն"
                                fullWidth
                                value={this.state.nameSurnameValue}
                                onInput={e => this.setState({nameSurnameValue: e.target.value})}
                            />
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
                                    <Box display="flex" alignItems="center">
                                        <Grid container>
                                            <InputLabel>Հետազության նախնական օրը</InputLabel>
                                            
                                            <Grid xs={12} item>
                                                <Box>
                                                    {!this.state.firstDayChange ? 
                                                    <KeyboardDatePicker
                                                        fullWidth
                                                        margin="normal"
                                                        format="MM/dd/yyyy"
                                                        cancelLabel={"отмена"}
                                                        okLabel={"Хорошо"}
                                                        placeholder="ամիս/օր/տարի"
                                                        value={this.state.firstDayValue !== "-" ? this.state.firstDayValue : null}
                                                        onChange={e => this.setState({firstDayValue: e})}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                    : 
                                                    <Grid container spacing={2}>
                                                        <Grid xs={6} item>
                                                            <KeyboardDatePicker
                                                                fullWidth
                                                                margin="normal"
                                                                format="MM/dd/yyyy"
                                                                cancelLabel={"отмена"}
                                                                okLabel={"Хорошо"}
                                                                placeholder="ամիս/օր/տարի"
                                                                value={this.state.firstDayMultOne === "null" ? null : this.state.firstDayMultOne}
                                                                onChange={e => this.setState({firstDayMultOne: e})}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid xs={6} item>
                                                            <KeyboardDatePicker
                                                                fullWidth
                                                                margin="normal"
                                                                format="MM/dd/yyyy"
                                                                cancelLabel={"отмена"}
                                                                okLabel={"Хорошо"}
                                                                placeholder="ամիս/օր/տարի"
                                                                value={this.state.firstDayMultTwo === "null" ? null : this.state.firstDayMultTwo}
                                                                onChange={e => this.setState({firstDayMultTwo: e})}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                <Grid xs={12} item>
                                    <Box>
                                        <RepeatIcon 
                                            onClick={this.firstDayChangeFunc}
                                            style={{cursor: "pointer"}}
                                        />

                                        <DeleteIcon 
                                            color="error"
                                            style={{cursor: "pointer"}}
                                            onClick={this.deleteFirstDayValue}
                                        />
                                    </Box>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>


                        <Grid item xs={12}>
                            <InputLabel>Հետազության վերջնական օրը</InputLabel>

                            <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
                                <KeyboardDatePicker
                                    fullWidth
                                    margin="normal"
                                    format="MM/dd/yyyy"
                                    cancelLabel={"отмена"}
                                    okLabel={"Хорошо"}
                                    placeholder="ամիս/օր/տարի"
                                    value={this.state.finalDay}
                                    onChange={e => this.setState({finalDay: e})}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "10px"}}>
                            <TextField
                                value={this.state.phoneValue}
                                onInput={e => this.setState({phoneValue: e.target.value})}
                                fullWidth
                                label="Հեռախոսահամար"
                            />                            
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <Box display="flex" alignItems="center">
                                <Grid container>
                                    <Grid xs={12} item>
                                        <InputLabel>ՈՒղեգրող բուժ հաստատություն</InputLabel>
                                        
                                        <Box>
                                            <TextField
                                                select
                                                value={this.state.clinic}
                                                onChange={e => this.setState({clinic: e.target.value})}
                                                fullWidth
                                            >
                                                {this.state.allClinics.map((option, key) => (
                                                    <MenuItem key={key} value={option}>{option}</MenuItem>
                                                ))}
                                            </TextField>
                                        </Box>
                                    </Grid>
                                                    
                                    <Grid xs={12} item>
                                        <Box display="flex" alignItems="center">
                                        <p>{this.state.addClinic}</p>
                                        {
                                            !this.state.addClinic ?
                                            <AddCircleOutlineIcon
                                                onClick={() => this.setState({addClinicModal: true})}
                                                cursor="pointer"
                                            />
                                            :
                                            <EditIcon 
                                                onClick={() => this.setState({addClinicModal: true})}
                                                style={{marginLeft: "5px", cursor: "pointer"}}
                                            />
                                        }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Dialog open={this.state.addClinicModal} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">Ավելացնել</DialogTitle>

                                <DialogContent>
                                    <TextField 
                                        required
                                        autoFocus
                                        error={this.state.addClinicError === "error" ? true : false}
                                        helperText={this.state.addClinicError === "error" ? "Լրացնել դաշտը" : ""}
                                        margin="dense"
                                        label="ՈՒղեգրող բուժ հաստատություն"
                                        value={this.state.addClinic}
                                        onInput={this.addClinicChange}
                                        style={{width: 300}}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.closeAddClinicModal(false)} color="primary">
                                        Չեղարկել
                                    </Button>
                                    
                                    <Button onClick={() => this.closeAddClinicModal(true)} color="primary">
                                        Հաստատել
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel>SPECT/CT տեսակ</InputLabel>

                            <Box>
                                <TextField select value={this.state.priceValue} onChange={e => this.setState({priceValue: e.target.value})} fullWidth>
                                    {this.state.spectPrice.map((option, key) => <MenuItem key={key} value={option.id}>{option.name}</MenuItem>)}
                                </TextField>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                label="Բուժող բժիշկ"
                                fullWidth
                                onChange={e => this.setState({doctorName: e.target.value})}
                                value={this.state.doctorName}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                label="Հիվանդության տեսակ"
                                fullWidth           
                                value={this.state.typeOfDiseaseValue}
                                onChange={e => this.setState({typeOfDiseaseValue: e.target.value})}
                            />                            
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <InputLabel>Քաշ</InputLabel>

                            <TextField
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => this.setState({weightValue: e.target.value})}
                                value={this.state.weightValue}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                type="email"
                                label="Էլ. հասցե"
                                fullWidth
                                onChange={e => this.setState({emailValue: e.target.value})}
                                value={this.state.emailValue === "-" ? "" : this.state.emailValue}
                            />
                        </Grid>

                        <Grid item xs={3} style={{marginTop: "20px"}}>
                                <TextField
                                    select
                                    label="Դիսկի առկայություն"
                                    value={this.state.discValue}
                                    onChange={e => this.setState({discValue: e.target.value})}
                                    fullWidth
                                >
                                
                                <MenuItem key="yes" value="yes">Այո</MenuItem>
                                <MenuItem key="no" value="no">Ոչ</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={3} style={{marginTop: "20px"}}>
                            <TextField
                                select
                                label="Փաստաթղթերի առկայություն"
                                value={this.state.documentsValue}
                                onChange={e => this.setState({documentsValue: e.target.value})}
                                fullWidth
                            >
                                <MenuItem key="yes" value="yes">Այո</MenuItem>
                                <MenuItem key="no" value="no">Ոչ</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={3} style={{marginTop: "20px"}}>
                            <TextField
                                select
                                label="Ստացման ձև"
                                value={this.state.receivedValue}
                                onChange={e => this.setState({receivedValue: e.target.value})}
                                fullWidth
                            >
                                
                                <MenuItem key="email" value="email">Էլ հասցե</MenuItem>
                                <MenuItem key="by_hand" value="by_hand">Առձեռն</MenuItem>
                                <MenuItem key="-" value="-">-</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={3} style={{marginTop: "20px"}}>
                            <TextField
                                select
                                label="Շաքարային Դիաբետ"
                                value={this.state.diabetes}
                                onChange={e => this.setState({diabetes: e.target.value})}
                                fullWidth
                            >
                                
                                <MenuItem key="yes" value="1">Այո</MenuItem>
                                <MenuItem key="no" value="0">Ոչ</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                                <TextField
                                    select
                                    label="Պետպատվեր"
                                    value={this.state.stateOrder}
                                    onChange={e => this.setState({stateOrder: e.target.value})}
                                    fullWidth
                                >
                                
                                <MenuItem key="yes" value="yes">Այո</MenuItem>
                                <MenuItem key="no" value="no">Ոչ</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                type="number"
                                value={this.state.visitsValue}
                                onChange={e => this.setState({visitsValue: e.target.value})}
                                fullWidth
                                label="Այցելության քանակը"
                            />
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <TextField
                                select
                                label="Բարեգործություն"
                                value={this.state.charity}
                                onChange={e => this.setState({charity: e.target.value})}
                                fullWidth
                            >
                                <MenuItem key="yes" value="yes">Այո</MenuItem>
                                <MenuItem key="no" value="no">Ոչ</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel id="demo-mutiple-name-label">Նշումներ</InputLabel>

                            <TextareaAutosize 
                                aria-label="minimum height" 
                                rowsMin={7}
                                value={this.state.notesValue === "-" ? "" : this.state.notesValue}
                                onChange={e => this.setState({notesValue: e.target.value})}
                                style={{width: "100%", marginTop: "10px"}}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained"
                            disabled={this.state.uploadDisabled}
                            component="label" startIcon={<CloudDownloadIcon />} fullWidth>
                                {this.state.fileName === "-" || this.state.fileName === "" ? "Ներբեռնել" : this.state.fileName}
                                <input type="file" hidden onChange={this.fileUpload} />
                            </Button>
                            
                            {this.state.progress !== 0 ?
                                <Box display="flex" alignItems="center">
                                    <Box width="100%" mr={1}>
                                        <LinearProgress variant="determinate" value={this.state.progress} />
                                    </Box>

                                    <Box minWidth={35}>
                                        <Typography variant="body2" color="textSecondary">{`${Math.round(this.state.progress)}%`}</Typography>
                                    </Box>
                                </Box>
                            : null}
                            
                            {this.state.fileNameError === "error" ? <Typography color="error">Կրկին փորձեք կամ թարմացրեք էջը</Typography> : null}
                            
                            {this.state.fileName ? 
                                this.state.fileName === "-" ? null : 
                                <Grid container style={{marginTop: "10px"}}>
                                    <Grid item>
                                        <Typography variant="body1" gutterBottom>{this.state.fileName}</Typography>
                                    </Grid>

                                    <Grid item> 
                                        {!this.state.isDeleteFile ? <DeleteIcon cursor="pointer" onClick={this.fileDeleteFunc} /> : <CircularProgress style={{width: "20px", height: "20px"}} />}
                                    </Grid>

                                    {this.state.fileDeleteError === "error" ? <Typography color="error">ֆայլը չի ջնջվում</Typography> : null}
                                </Grid>
                             : null}
                        </Grid>

                        <Grid item xs={12}>
                            {!this.state.isLoadedDisable ?  !this.state.isLoadedButton ? 
                            <Button variant="contained" onClick={this.updateFunc} data-action='submit' color="primary">Հաստատել</Button>  : 
                            <CircularProgress /> : null}

                            {this.state.successMessage ? 
                            <Button variant="contained" color="secondary" 
                            style={{backgroundColor: "green", marginBottom: "20px"}} startIcon={<CheckIcon />}>
                                Փոփոխությունը կատարվել է հաջողությանբ
                            </Button> : null}

                            {this.state.errorMessage ? <Button variant="contained" color="secondary" style={{marginBottom: "20px"}} startIcon={<CancelIcon />}>
                                Դաշտերը լրացրել եք սխալ
                            </Button> : null}

                        </Grid>

                    </Grid>
                }
            </React.Fragment>            
            </div>
        )
    }
}

export default withRouter(SpectCtEdit);