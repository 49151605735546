import React, { useState, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'date-fns';
import { format } from 'date-fns';
import RuLocalizedUtils from '../coreData/RuLocalizedUtils';
import ruLocale from "date-fns/locale/ru";
import Alert from '@material-ui/lab/Alert';

import dateFormat from '../usefulFunction/DateFormat';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: 150
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  addDay: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, 0%)',
    cursor: 'pointer'
  },
  alert: {
    marginBottom: "20px"
  }
});

export default function Pet() {
  const history = useHistory();

  const classes = useStyles();

  const [lists, setLists] = useState([]);

  const [addError, setAddError] = useState(false);
  const [isInputDisable, setIsInputDisable] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)

  const [searchData, setSearchData] = useState(new Date())

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/all-list`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": "Bearer " + localStorage.getItem("appname_token")
      }
    }).then(res => res.json())
      .then(json => {
        setLists(json)
        setDataLoaded(true)
      }).catch(err => console.log(err))
  }, [])

  const createList = e => {
    setIsInputDisable(true)

    fetch(`${process.env.REACT_APP_API_URL}/add-list`, {
      method: "POST",
      body: JSON.stringify({ data: { date: format(e, 'yyyy-MM-dd') } }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": "Bearer " + localStorage.getItem("appname_token")
      }
    })
      .then(res => res.json())
      .then(json => {
        if (json.status === "Success") {
          history.push("/pet-list/" + json.id)
        } else if (json.status === "Have") {
          setAddError("Այս ամսաթիվը զբաղված է")
        } else {
          setAddError("Փորձել նորից")
        }

        setIsInputDisable(false)

        setTimeout(function () {
          setAddError(false)
        }, 2500)
      }).catch(() => {
        setIsInputDisable(false)
        setAddError("Փորձել նորից")

        setTimeout(function () {
          setAddError(false)
        }, 2500)
      })
  }

  const deletePetList = id => {
    if (window.confirm("Համոզված եք որ ուզում եք ջնջնել")) {
      setIsDeleted(true);

      fetch(`${process.env.REACT_APP_API_URL}/delete-list/${id}`, {
        method: "DELETE",
        headers: { "Content-type": "application/json; charset=UTF-8", "Authorization": "Bearer " + localStorage.getItem("appname_token") }
      })
        .then(res => res.json())
        .then(json => {
          setIsDeleted(false)

          if (json.status === "Success") {
            setDeleteSuccess(true)

            setTimeout(function () {
              setDeleteSuccess(false)
            }, 3000)

            let filteredData = lists.filter(item => item.id !== id)

            setLists(filteredData)

          } else {
            setDeleteError(true)

            setTimeout(function () {
              setDeleteError(false)
            }, 3000)
          }
        }).catch(() => {
          setIsDeleted(false)
          setDeleteError(true)

          setTimeout(function () {
            setDeleteError(false)
          }, 2000)
        })
    }
  }

  const printData = data => {
    if (data.length) {
      return (
        data.map(item => {
          return (
            <Grid key={item.id} item sm={6} xs={12}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>{dateFormat(item.date, "yes")}</Typography>
                  <Typography variant="h5" component="h2">{item.count} հետազոտվող</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => history.push("/pet-list/" + item.id)}
                  >
                    Դիտել ավելին
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={isDeleted}
                    startIcon={<DeleteIcon />}
                    onClick={() => deletePetList(item.id)}
                  >
                    Ջնջել
                  </Button>

                </CardActions>
              </Card>
            </Grid>
          )
        })
      )
    } else {
      return <h2>Տվյալններ չկա</h2>
    }
  }

  const searchFunc = e => {
    setSearchData(e);
    setDataLoaded(false);

    fetch(`${process.env.REACT_APP_API_URL}/search_patients/${format(e, 'yyyy-MM-dd')}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": "Bearer " + localStorage.getItem("appname_token")
      }
    }).then(res => res.json())
      .then(json => {
        setLists(json)
        setDataLoaded(true)
      }).catch(err => console.log(err))
  }

  const getAllData = () => {
    setDataLoaded(false);
    
    fetch(`${process.env.REACT_APP_API_URL}/all-list`, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": "Bearer " + localStorage.getItem("appname_token")
      }
    }).then(res => res.json())
    .then(json => {
        setLists(json)
        setDataLoaded(true)
    }).catch(err => console.log(err))
  }

  return (
    <React.Fragment>
      {deleteError ? <Alert variant="filled" severity="error" className={classes.alert}>Խնդրում ենք նորից փորձել</Alert> : null}
      
      {deleteSuccess ? <Alert variant="filled" severity="success" className={classes.alert}>Տվյալը ջնջվել է հաջողությանբ</Alert> : null}

      {addError ? <Alert variant="filled" severity="error">{addError}</Alert> : null}

      <Grid item sm={6} xs={12}>
        <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
          <KeyboardDatePicker
            fullWidth
            margin="normal"
            format="MM/dd/yyyy"
            cancelLabel={"отмена"}
            okLabel={"Хорошо"}
            placeholder="ամիս/օր/տարի"
            value={searchData}
            onChange={e => searchFunc(e)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>

        <Button variant="contained" onClick={getAllData}>Բոլոր տվյալնները</Button>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <Card className={classes.root}>
            <CardContent>
              <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
                <KeyboardDatePicker
                  fullWidth
                  disabled={isInputDisable}
                  margin="normal"
                  format="MM/dd/yyyy"
                  cancelLabel={"отмена"}
                  okLabel={"Хорошо"}
                  placeholder="ամիս/օր/տարի"
                  onChange={e => createList(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>

              <p>ամիս/օր/տարի</p>
            </CardContent>
          </Card>
        </Grid>

        {!dataLoaded ? <CircularProgress /> : <React.Fragment>{printData(lists)}</React.Fragment>}
      </Grid>
    </React.Fragment>
  );
}