import React from "react";

import Upload from '../FileUpload/Upload';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

//Icons
import BackupIcon from '@material-ui/icons/Backup';
import CheckIcon from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import RefreshIcon from '@material-ui/icons/Refresh';

import dateCheck from "../usefulFunction/DateCheck";

// import 'date-fns';
import CircularProgress from "@material-ui/core/CircularProgress";

//Tooltip
import Tooltip from '@material-ui/core/Tooltip';

import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12.4px",
      }
    }
  }
});

class Archive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoaded: false,
      dataFilter: "",
      changeDateFilter: false,
      firstDateFilter: new Date(),
      takenNumber: 20,
      allDataCount: 0,
      checkAllSwitch: false,
      disableMoreLess: false,
      delteAllState: false,
      allDeletesIds: [],
      archiveLoader: false,
      archiveSuccess: false,
      uploadFormOpen: false,
      userId: 0,
      isArchiveChecked: true
    };

    this.changeArchiveStatus = this.changeArchiveStatus.bind(this);
    this.archiveStatus = this.archiveStatus.bind(this);
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/all-archive`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("appname_token")
      }
    })
      .then(res => res.json())
      .then(res => {
        this.setState({ data: res, isLoaded: true, allDataCount: res.count });
      })
      .catch(err => {
        console.log(err);
      });
  }

  changeArchiveStatus = (id, e) => {
    if (e.target.checked) {
      e.currentTarget.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = "#8fd19e";
    } else {
      e.currentTarget.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = "#fff";
    }
    
    fetch(`${process.env.REACT_APP_API_URL}/data/checkArchive`, {
      method: "POST",
      body: JSON.stringify({id: id, status: e.target.checked}),
      headers: { "Content-type": "application/json; charset=UTF-8", "Authorization": "Bearer " + localStorage.getItem("appname_token")},
    })
  };

  archiveStatus() {
    if (this.state.archiveSuccess) {
      setTimeout(() => {
        this.setState({ archiveSuccess: false });
      }, 2500);
    }

    return this.state.archiveSuccess ? (
      <Button
        variant="contained"
        color="secondary"
        style={{ backgroundColor: "green", marginTop: "20px" }}
        startIcon={<CheckIcon />}
      >
        Տվյալը ապաարխիվացվել է հաջողությանբ
      </Button>
    ) : (
      ""
    );
  }

  setData(data) {
    return !data.length ? (
      <TableRow key={1}>
        <TableCell>Տվյալններ չկա</TableCell>
      </TableRow>
    ) : (
      data.map(row => {
        return (
          <MuiThemeProvider key={row.id} theme={theme}>
            <TableRow style={{ backgroundColor: this.state.isArchiveChecked ? "#8fd19e" : "#fff"}}>
              <TableCell>{row.name_surname}</TableCell>
              <TableCell>{dateCheck(row.first_day)}</TableCell>
              <TableCell>{row.type_of_disease}</TableCell>

              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  href={`/more/` + row.id}
                >
                  Ավելին
                </Button>
              </TableCell>

              <TableCell>
                <Tooltip title="Չեղարկել և հանել արխիվացումից">
                  <Checkbox
                    defaultChecked
                    color="primary"
                    onChange={e => this.changeArchiveStatus(row.id, e)}
                  />
                </Tooltip>
              </TableCell>

              <TableCell>
                <Tooltip title="Կցել ֆայլեր">
                  <BackupIcon 
                    onClick={() => this.setState({
                      uploadFormOpen: true,
                      userId: row.id
                    })}
                    cursor="pointer" 
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          </MuiThemeProvider>
        );
      })
    );
  }

  openUploadForm = val => {
    this.setState({uploadFormOpen: val});
  }
  
  render() {
    const refreshAllData = () => {
      this.setState({isLoaded: false});

      fetch(`${process.env.REACT_APP_API_URL}/all-archive`, {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("appname_token")
        }
      })
      .then(res => res.json())
      .then(res => this.setState({data: res, isLoaded: true, allDataCount: res.count}))
      .catch(err => console.log(err))
    }

    return (
      <div>

        {this.state.uploadFormOpen ? <Upload openForm={this.openUploadForm} userId={this.state.userId} /> : null}

        <div>{this.archiveStatus()}</div>

        <Paper style={{ marginTop: "10px" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Անուն Ազգանուն</strong>
                </TableCell>

                <TableCell>
                  <strong>Հետազոտության օրը</strong>
                </TableCell>

                <TableCell>
                  <strong>Հիվանդության տեսակ</strong>
                </TableCell>

                <TableCell>
                  <Tooltip title="Թարմացնել տվյալնները">
                    <RefreshIcon style={{cursor: "pointer"}} onClick={refreshAllData} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!this.state.isLoaded ? (
                <TableRow key={1}>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                this.setData(this.state.data)
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default Archive;