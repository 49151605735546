import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Patient(props) {
    const [items, setItems] = useState(null)
    
    const handleClose = () => props.onClick({ status: false });
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/patient/${props.id}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8", 
                "Authorization": "Bearer " + localStorage.getItem("appname_token") 
            }
        })
        .then(res => res.json())
        .then(json => setItems(json))
        .catch(err => console.log(err))
    }, [])

    return (
        <Dialog open={props.isOpen} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
            <DialogTitle id="simple-dialog-title">{items ? items.name_surname + " - համար " + (items.number ? items.number : "չկա") : null}</DialogTitle>

            <DialogContent>
                {!items ? <CircularProgress /> : <List>
                    <ListItem>
                        <ListItemText primary="Հասակ" secondary={items.height ? items.height + " սմ" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="Քաշ" secondary={items.weight ? items.weight + " կգ" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="Ջերմաստիճան" secondary={items.temperature ? items.temperature + " c°" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="Գլյուկոզա" secondary={items.glucose ? items.glucose + " մմլ/լ" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="Զարկերակի ճնշ." secondary={items.pressure ? items.pressure + " մմ/սա" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="Պուլս" secondary={items.pulse ? items.pulse + " զ/ր" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="SpO2" secondary={items.spo ? items.spo + "%" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="FDG տրվեց" secondary={items.fdg_mbq ? items.fdg_mbq + " MBq / " + items.fdg_hour + " ժամ" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="Ներարկվել է" secondary={items.inject_mbq ? items.inject_mbq + " MBq / " + items.inject_hour + " ժամ" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="Մնացորդ" secondary={items.balance ? items.balance + " MBq" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="FDG ակտ." secondary={items.fdg_active ? items.fdg_active + " MBq" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="Դուրս գրում" secondary={items.write_out_msv ? items.write_out_msv + " mSv / " + items.write_out_hour + " ժամ" : "Տվյալը բացակայում է"} />
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="Նշումներ" secondary={items.notes ? items.notes : "Տվյալը բացակայում է"} />
                    </ListItem>
                </List>}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">Փակել</Button>
            </DialogActions>
        </Dialog>
    );
}