import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'

import 'date-fns';
import { format } from 'date-fns';
import RuLocalizedUtils from '../coreData/RuLocalizedUtils';
import ruLocale from "date-fns/locale/ru";

import { MuiPickersUtilsProvider, KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

//Icons
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';

function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
}

function CtCreate () {
    let history = useHistory();

    //Clinic States
    const [clinic, setClinic] = useState('-');
    const [addClinic, setAddClinic] = useState("");
    const [addClinicError, setAddClinicError] = useState("");
    const [addClinicModal, setAddClinicModal] = useState(false);
    const [allClinics, setAllClinics] = useState([
        '<<Ֆանարջյան>> Կլինիկա', 
        '<<Յոլյանի անվան արյունաբանական կենտրոն>>',
        '<<Նաիրի>> ԲԿ',
        '<<Միքայելյան վիրաբուժության ինստիտուտ>>',
        '<<Էրեբունի>> ԲԿ',
        '<<Հայ-Ամերիկյան առողջության կենտրոն>>',
        '<<Աստղիկ>> ԲԿ',
        "-"
    ]);

    const [treatmentName, setTreatmentName] = useState(["-"]);

    const [firstDayChange, setFirstDayChange] = useState(false);

    const [firstDayOne, setFirstDayOne] = useState(null);

    const [time, setTime] = useState(new Date());

    const [birthday, setBirthday] = useState(null);

    const [firstDayMultOne, setfirstDayMultOne] = useState(new Date());
    const [firstDayMultTwo, setfirstDayMultTwo] = useState(new Date());

    //Doctor States
    const [doctorName, setDoctorName] = useState("");

    const [nameSurnameInp, setNameSurnameInp]   = useState('');
    const [phoneInp, setPhoneInp]               = useState('');
    const [typeOfDisease, setTypeOfDisease]     = useState('');
    const [weight, setWeight]                   = useState(0);
    const [height, setHeight]                   = useState(0);
    const [email, setEmail]                     = useState('');
    const [disc, setDisc]                       = useState('no');
    const [documents, setDocuments] = useState('no');
    const [received, setReceived] = useState("-");
    const [visits, setVisits] = useState(1);
    const [notes, setNotes] = useState("");
    const [diabetes, setDiabetes] = useState("no");

    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedDisable, setIsLoadedDisable] = useState(false);

    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorMessageText, setErrorMessageText] = useState('Դաշտերը լրացրել եք սխալ');

    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState("");
    const [fileNameError, setFileNameError] = useState("");
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [fileDeleteError, setFileDeleteError] = useState("");
    const [isDeleteFile, setIsDeleteFile] = useState(false);

    const [ctType, setCtType] = useState([]);

    const [passport, setPassport] = useState("");

    const [ctPriceList, setCtPriceList] = useState([]);

    const [iodine, setIodine] = useState("no");

    const [stateOrder, setStateOrder] = useState("no");

    const [uploadedFile, setUploadedFile] = useState("");

    const [charity, setCharity] = useState("no")

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/ct-price-create`, {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(res => {
            setCtType([res[0].id])
            setCtPriceList(res)
        })
        .catch(err => console.log(err))
    }, [])

    const sendToDB = () => {
        setIsLoaded(true);

        let obj = {
            'name_surname': nameSurnameInp,
            'research_day': firstDayOne ? format(firstDayOne, 'yyyy-MM-dd') : "",
            'ct_type': ctType.join(","),
            'phone': phoneInp,
            'referral_medical_institution': clinic,
            'doctor': doctorName,
            'type_of_disease': typeOfDisease,
            'weight': weight,
            'email': email,
            'notes': notes,
            'height': height,
            'birthday': birthday ? format(birthday, 'yyyy-MM-dd') : "",
            'time': time ? time : "",
            'passport': passport,
            'iodine': iodine,
            'diabetes': diabetes,
            'state_order': stateOrder,
            'file_name': fileName,
            'charity': charity,
        }

        fetch(`${process.env.REACT_APP_API_URL}/ct-patient-create`, {
            method: "POST",
            body: JSON.stringify({data: obj}),
            headers: {"Content-type": "application/json; charset=UTF-8", "Authorization": "Bearer " + localStorage.getItem("appname_token")}
        })
        .then(res => res.json()) 
        .then(json => {
            if (json.status === "Success") {
                setIsLoaded(false);
                setSuccessMessage(true);

                setTimeout(() => {
                    setSuccessMessage(false);
                }, 2500);
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const closeAddClinicModal = param => {
        if (param) {
            if (!addClinic) {
                setAddClinicError("error");
            } else {
                setAllClinics([...allClinics, addClinic]);
                setClinic(addClinic);

                setAddClinicModal(false);
                setAddClinicError("");
            }
        } else {
            if (addClinic) {
                let removeItem = allClinics.filter(item => {
                    return item !== addClinic;
                });

                setAllClinics(removeItem);
                setClinic("-");
            }

            setAddClinicModal(false);
            setAddClinic("");
            setAddClinicError("");
        }
    }

    const addClinicChange = (e) => {

        if (e.target.value) {
            setAddClinicError("");
        }

        setAddClinic(e.target.value);
    }

    const fileUpload = e => {
        let data = new FormData();
        data.append('file', e.target.files[0]);
    
        let request = new XMLHttpRequest();
        request.open('POST', `${process.env.REACT_APP_API_URL}/ct-file-upload`);
        request.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("appname_token"));
        
        request.upload.addEventListener('progress', function(e) {
            let percent_complete = (e.loaded / e.total)*100;
            percent_complete < 100 ? setProgress(percent_complete) : setProgress(100);
        });
        
        request.addEventListener('load', function() {
            if (request.status === 200) {
                let res = JSON.parse(request.response);
                                
                if (res.status === "No File") {
                    setFileNameError("error");
                    setProgress(0);
                } else {
                    setUploadDisabled(true);
                    setFileName(res.name);
                }
            }

            e.target.value = "";
        });
    
        request.send(data);
    }

    const deleteFile = () => {
        setIsDeleteFile(true);

        fetch(`${process.env.REACT_APP_API_URL}/ct-file-upload-delete/${fileName}`, {
            method: 'DELETE',
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(res => {
            setIsDeleteFile(false);
            
            if (res.status === "Success") {
                setFileDeleteError("");
                setFileName("");
                setProgress(0);
                setUploadDisabled(false);
            } else {
                setFileDeleteError("error");
            }
        }).catch(error => {
            setIsDeleteFile(false);
            console.error(error);
        })
    }

    return (
        <React.Fragment>
            <KeyboardBackspaceIcon onClick={() => history.push("/ct-patients")} style={{cursor: "pointer"}} />
      <Grid container spacing={3}>
          
        <Grid item xs={12} sm={12}>
            <TextField
                label="Անուն Ազգանուն"
                fullWidth
                value={nameSurnameInp}
                onInput={e => setNameSurnameInp(e.target.value)}
            />
        </Grid>
 
        <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
            <Grid item xs={12} style={{marginTop: "20px"}}>
                <Box display="flex" alignItems="center">
                    <Grid container>
                    <InputLabel>Հետազության օրը</InputLabel>

                        <Grid xs={12} item>
                        <KeyboardDatePicker
                                    fullWidth
                                    margin="normal"
                                    format="MM/dd/yyyy"
                                    cancelLabel={"отмена"}
                                    okLabel={"Хорошо"}
                                    placeholder="ամիս/օր/տարի"
                                    value={firstDayOne}
                                    onChange={e => setFirstDayOne(e)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
            <Grid item xs={12} style={{marginTop: "20px"}}>
                <Box display="flex" alignItems="center">
                    <Grid container>
                    <InputLabel>Հետազոտության ժամը</InputLabel>

                        <Grid xs={12} item>
                        <TimePicker
                                    fullWidth
                                    margin="normal"
                                    format="hh:mm"
                                    cancelLabel={"отмена"}
                                    okLabel={"Хорошо"}
                                    value={time}
                                    onChange={e => setTime(e)}
                                />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
            <Grid item xs={12} style={{marginTop: "20px"}}>
                <Box display="flex" alignItems="center">
                    <Grid container>
                    <InputLabel>Ծննդյան օրը</InputLabel>

                        <Grid xs={12} item>
                        <KeyboardDatePicker
                                    fullWidth
                                    margin="normal"
                                    format="MM/dd/yyyy"
                                    cancelLabel={"отмена"}
                                    okLabel={"Хорошо"}
                                    placeholder="ամիս/օր/տարի"
                                    value={birthday}
                                    onChange={e => setBirthday(e)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </MuiPickersUtilsProvider>

        <Grid item xs={12}>
            <TextField
                value={passport}
                label="Անձնագրի սերիա"
                onInput={e => setPassport(e.target.value)}
                fullWidth
            />
        </Grid>
        
        <Grid item xs={12}>
            <InputLabel id="demo-mutiple-name-label">Ct տեսակ</InputLabel>
            
            <Select
                fullWidth
                multiple
                value={ctType}
                onChange={e => setCtType(e.target.value)}
                input={<Input />}
            >
            {ctPriceList.map((option, key) => <MenuItem key={key} value={option.id}>{option.name}</MenuItem>)}
            </Select>
            {/* <InputLabel>Ct տեսակ</InputLabel>

            <Box>
                <TextField 
                    select 
                    value={ctType} 
                    onChange={e => setCtType(e.target.value)}
                    fullWidth
                >
                    {ctPriceList.map((option, key) => <MenuItem key={key} value={option.id}>{option.name}</MenuItem>)}
                </TextField>
            </Box> */}
        </Grid>

        <Grid item xs={12}>
            <TextField
                value={phoneInp}
                label="Հեռախոսահամար"
                onInput={e => setPhoneInp(e.target.value)}
                fullWidth
            />
        </Grid>
        
        <Grid item xs={12} style={{marginTop: "20px"}}>
            <Box display="flex" alignItems="center">
                <Grid container>                    
                    <Grid xs={12} item>
                        <InputLabel>ՈՒղեգրող բուժ հաստատություն </InputLabel>

                        <Box>
                            <TextField select value={clinic} onChange={e => setClinic(e.target.value)} fullWidth>
                                {allClinics.map((option, key) => <MenuItem key={key} value={option}>{option}</MenuItem>)}
                            </TextField>
                        </Box>
                    </Grid>

                    <Grid xs={12} item>
                        <Box display="flex" alignItems="center">
                            <p>{addClinic}</p>
                            {
                                !addClinic ? 
                                <AddCircleOutlineIcon
                                    onClick={() => setAddClinicModal(true)}
                                    cursor="pointer"
                                />
                                :
                                <EditIcon 
                                    onClick={() => setAddClinicModal(true)}
                                    style={{marginLeft: "5px", cursor: "pointer"}}
                                />
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Dialog open={addClinicModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Ավելացնել</DialogTitle>

                <DialogContent>
                    <TextField 
                        required
                        autoFocus
                        error={addClinicError === "error" ? true : false}
                        helperText={addClinicError === "error" ? "Լրացնել դաշտը" : ""}
                        margin="dense"
                        label="ՈՒղեգրող բուժ հաստատություն"
                        value={addClinic}
                        onInput={addClinicChange}
                        style={{width: 300}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeAddClinicModal(false)} color="primary">
                        Չեղարկել
                    </Button>
                    
                    <Button onClick={() => closeAddClinicModal(true)} color="primary">
                        Հաստատել
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>

        <Grid item xs={12} sm={12}>
            <TextField
                label="Հետազոտող բժիշկ"
                fullWidth
                onChange={e => setDoctorName(e.target.value)}
                value={doctorName}
            />
        </Grid>
    
        <Grid item xs={12} sm={12}>
            <TextField
                label="Հիվանդության տեսակ"
                fullWidth
                onChange={e => setTypeOfDisease(e.target.value)}
                value={typeOfDisease}
            />
        </Grid>

        <Grid item xs={4}>
            <TextField
                select
                label="Ալերգիա յոդից"
                value={iodine}
                onChange={e => setIodine(e.target.value)}
                fullWidth
            >
                <MenuItem key="yes" value="yes">Այո</MenuItem>
                <MenuItem key="no" value="no">Ոչ</MenuItem>
            </TextField>
        </Grid>

        <Grid item xs={4}>
            <TextField
                select
                label="Շաքարային դիաբետ"
                value={diabetes}
                onChange={e => setDiabetes(e.target.value)}
                fullWidth
            >
                <MenuItem key="yes" value="yes">Այո</MenuItem>
                <MenuItem key="no" value="no">Ոչ</MenuItem>
            </TextField>
        </Grid>

        <Grid item xs={4}>
            <TextField
                select
                label="Պետպատվեր"
                value={stateOrder}
                onChange={e => setStateOrder(e.target.value)}
                fullWidth
            >
                <MenuItem key="yes" value="yes">Այո</MenuItem>
                <MenuItem key="no" value="no">Ոչ</MenuItem>
            </TextField>
        </Grid>

        <Grid item xs={12} style={{marginTop: "20px"}}>
            <InputLabel>Քաշ</InputLabel>
            
            <TextField
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setWeight(e.target.value)}
                value={weight}
            />
        </Grid>

        <Grid item xs={12} style={{marginTop: "20px"}}>
            <InputLabel>Հասակ</InputLabel>
            
            <TextField
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setHeight(e.target.value)}
                value={height}
            />
        </Grid>

        <Grid item xs={12}>
            <TextField
                type="email"
                label="Էլ. հասցե"
                fullWidth
                onChange={e => setEmail(e.target.value)}
                value={email}
            />
        </Grid>

        <Grid item xs={12} style={{marginTop: "20px"}}>
            <TextField
                select
                label="Բարեգործություն"
                value={charity}
                onChange={e => setCharity(e.target.value)}
                fullWidth
            >
                <MenuItem key="yes" value="yes">Այո</MenuItem>
                <MenuItem key="no" value="no">Ոչ</MenuItem>
            </TextField>
        </Grid>

        <Grid item xs={12}>
            <InputLabel id="demo-mutiple-name-label">Նշումներ</InputLabel>

            <TextareaAutosize 
                aria-label="minimum height" 
                rowsMin={7}
                value={notes}
                onChange={e => setNotes(e.target.value)}
                style={{width: "100%", marginTop: "10px"}}
            />
        </Grid>

        <Grid item xs={12}>
            <Button variant="contained" disabled={uploadDisabled} component="label" startIcon={<CloudDownloadIcon />} fullWidth>
                {!uploadedFile ? "Ներբեռնել" : uploadedFile.name}
                
                <input type="file" hidden onChange={fileUpload} />
            </Button>

            {progress !== 0 ? <LinearProgressWithLabel value={progress} /> : null}

            {fileNameError === "error" ? <Typography color="error">Կրկին փորձեք կամ թարմացրեք էջը</Typography> : null}

            {fileName ? <Grid container>
                <Grid item>
                    <Typography variant="body1" gutterBottom>{fileName}</Typography>
                </Grid>

                <Grid item>
                    {!isDeleteFile ? <DeleteIcon cursor="pointer" onClick={deleteFile} /> : <CircularProgress style={{width: "20px", height: "20px"}} />} 
                </Grid>

                {fileDeleteError === "error" ? <Typography color="error">ֆայլը չի ջնջվում</Typography> : null}
            </Grid> : null}
        </Grid>
      
        <Grid item xs={12}>
            {
                !isLoadedDisable ? 
                    !isLoaded ? <Button variant="contained" color="primary" data-action='submit' onClick={sendToDB}>Հաստատել</Button>  : <CircularProgress />
                : null
            }
        </Grid>

        <Grid item xs={12}>
            {successMessage ? <Button variant="contained" style={{backgroundColor: "green", color: "#fff", marginBottom: "20px"}} startIcon={<CheckIcon />}>Ավելացումը կատարվել է հաջողությանբ</Button> : null}

            {errorMessage ? <Button variant="contained" color="secondary" style={{marginBottom: "20px"}} startIcon={<CancelIcon />}>
                {errorMessageText}
            </Button> : null}
        </Grid>
      </Grid>
    </React.Fragment>
    )
}

export default CtCreate;