import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import 'date-fns';
import { format } from 'date-fns';
import RuLocalizedUtils from './RuLocalizedUtils';
import ruLocale from "date-fns/locale/ru";

import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import RepeatIcon from '@material-ui/icons/Repeat';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

//Icons
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
}

export default function Create() {

    //Clinic States
    const [clinic, setClinic] = useState('-');
    const [addClinic, setAddClinic] = useState("");
    const [addClinicError, setAddClinicError] = useState("");
    const [addClinicModal, setAddClinicModal] = useState(false);
    const [allClinics, setAllClinics] = useState([
        '<<Ֆանարջյան>> Կլինիկա', 
        '<<Յոլյանի անվան արյունաբանական կենտրոն>>',
        '<<Նաիրի>> ԲԿ',
        '<<Միքայելյան վիրաբուժության ինստիտուտ>>',
        '<<Էրեբունի>> ԲԿ',
        '<<Հայ-Ամերիկյան առողջության կենտրոն>>',
        '<<Աստղիկ>> ԲԿ',
        "-"
    ]);

    const [treatmentName, setTreatmentName] = useState(["-"]);

    const [firstDayChange, setFirstDayChange] = useState(false);

    const [firstDayOne, setFirstDayOne] = useState(null);

    const [firstDayMultOne, setfirstDayMultOne] = useState(new Date());
    const [firstDayMultTwo, setfirstDayMultTwo] = useState(new Date());

    //Doctor States
    const [doctorName, setDoctorName] = useState("");

    const [nameSurnameInp, setNameSurnameInp]   = useState('');
    const [phoneInp, setPhoneInp]               = useState('');
    const [typeOfDisease, setTypeOfDisease]     = useState('');
    const [weight, setWeight]                   = useState(0);
    const [email, setEmail]                     = useState('');
    const [disc, setDisc]                       = useState('no');
    const [documents, setDocuments] = useState('no');
    const [received, setReceived] = useState("-");
    const [visits, setVisits] = useState(1);
    const [notes, setNotes] = useState("");
    const [diabetes, setDiabetes] = useState(0);

    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedDisable, setIsLoadedDisable] = useState(false);

    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorMessageText, setErrorMessageText] = useState('Դաշտերը լրացրել եք սխալ');

    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState("");
    const [fileNameError, setFileNameError] = useState("");
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [fileDeleteError, setFileDeleteError] = useState("");
    const [isDeleteFile, setIsDeleteFile] = useState(false);

    const [stateOrder, setStateOrder] = useState("no");

    const [finalDay, setFinalDay] = useState(null);

    const [charity, setCharity] = useState("no")

    const sendToDB = () => {
        setIsLoaded(true);

        let first_day = '';

        if (!firstDayChange) {
            if (firstDayOne === null) {
                first_day = "-";
            } else {
                first_day = format(firstDayOne, 'yyyy-MM-dd');
            }
        } else {
            first_day = format(firstDayMultOne, 'yyyy-MM-dd') + "/" + format(firstDayMultTwo, 'yyyy-MM-dd');
        }

        let obj = {
            'name_surname': nameSurnameInp === "" ? "-" : nameSurnameInp,
            'first_day': first_day,
            'phone': phoneInp === '' ? '-' : phoneInp,
            'medical': clinic,
            'doctor': doctorName === "" ? "-" : doctorName,
            'type_of_disease': typeOfDisease === "" ? "-" : typeOfDisease,
            'weight': weight === "" ? 0 : weight,
            'email': email === "" ? "-" : email,
            'disc': disc,
            'documents': documents,
            'received': received,
            'visits': visits === "" ? 1 : visits,
            'notes': notes === "" ? "-" : notes,
            'is_deleted': 0,
            'rejected': 0,
            'archive': 0,
            'file_name': fileName === "" ? "-" : fileName,
            'diabetes': diabetes,
            'state_order': stateOrder,
            'charity': charity,
            'final_day': finalDay ? format(finalDay, 'yyyy-MM-dd') : null,
            'user_name': localStorage.getItem("user_name")
        }

        fetch(`${process.env.REACT_APP_API_URL}/data`, {
            method: "POST",
            body: JSON.stringify({data: obj}),
            headers: {"Content-type": "application/json; charset=UTF-8", "Authorization": "Bearer " + localStorage.getItem("appname_token")}
        })
        .then(response => response.json()) 
        .then(json => {
            if (json.nameErrorMessage) {
                setErrorMessageText(json.nameErrorMessage);
            }

            setIsLoadedDisable(true);
            
            if (json.status === "Success") {
                setSuccessMessage(true);

                setNameSurnameInp("");
                setFirstDayOne(null);
                setfirstDayMultOne(null);
                setfirstDayMultTwo(null);
                setPhoneInp("");
                setClinic("-");
                setDoctorName("");
                setTypeOfDisease("");
                setWeight(0);
                setEmail("");
                setDisc("no");
                setDocuments("no");
                setReceived("-");
                setVisits(1);
                setNotes("");
                setAddClinic("");

                setFileName("");
                setProgress(0);
                setFileNameError("");
                setUploadDisabled(false);

                setDiabetes(0);

                setTimeout(() => {
                    setIsLoadedDisable(false);
                    setIsLoaded(false);
                    setSuccessMessage(false);
                }, 1500);
            } else {
                setErrorMessage(true);

                setTimeout(() => {
                    setIsLoadedDisable(false);
                    setIsLoaded(false);
                    setErrorMessage(false);
                }, 2500);
            }
        }).catch(err => {
            setErrorMessage(true);
            setIsLoadedDisable(true);

            setTimeout(() => {
                setIsLoadedDisable(false);
                setIsLoaded(false);
                setErrorMessage(false);
            }, 2500);
        })
    }

    const firstDayChangeFunc = () => {
        if (!firstDayChange) {
            setFirstDayChange(true);
        } else {
            setFirstDayChange(false);
        }
    }

    const closeAddClinicModal = param => {
        if (param) {
            if (!addClinic) {
                setAddClinicError("error");
            } else {
                setAllClinics([...allClinics, addClinic]);
                setClinic(addClinic);

                setAddClinicModal(false);
                setAddClinicError("");
            }
        } else {
            if (addClinic) {
                let removeItem = allClinics.filter(item => {
                    return item !== addClinic;
                });

                setAllClinics(removeItem);
                setClinic("-");
            }

            setAddClinicModal(false);
            setAddClinic("");
            setAddClinicError("");
        }
    }

    const addClinicChange = (e) => {

        if (e.target.value) {
            setAddClinicError("");
        }

        setAddClinic(e.target.value);
    }

    const fileUpload = e => {        
        let data = new FormData();
        data.append('file', e.target.files[0]);
    
        let request = new XMLHttpRequest();
        request.open('POST', `${process.env.REACT_APP_API_URL}/data/file-upload`);
        request.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("appname_token"));
        
        request.upload.addEventListener('progress', function(e) {
            let percent_complete = (e.loaded / e.total)*100;
            percent_complete < 100 ? setProgress(percent_complete) : setProgress(100);
        });
        
        request.addEventListener('load', function() {
            if (request.status === 200) {
                let res = JSON.parse(request.response);
                                
                if (res.status === "No File") {
                    setFileNameError("error");
                    setProgress(0);
                } else {
                    setUploadDisabled(true);
                    setFileName(res.name);
                }
            }

            e.target.value = "";
        });
    
        request.send(data);
    }

    const deleteFile = () => {
        setIsDeleteFile(true);

        fetch(`${process.env.REACT_APP_API_URL}/data/file-delete/${fileName}`, {
            method: 'DELETE',
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        }).then(response => response.json())
        .then(res => {
            setIsDeleteFile(false);
            
            if (res.status === "Success") {
                setFileDeleteError("");
                setFileName("");
                setProgress(0);
                setUploadDisabled(false);
            } else {
                setFileDeleteError("error");
            }
        }).catch(error => {
            setIsDeleteFile(false);
            console.error(error);
        })
    }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
          
        <Grid item xs={12} sm={12}>
            <TextField
                label="Անուն Ազգանուն"
                fullWidth
                value={nameSurnameInp}
                onInput={e => setNameSurnameInp(e.target.value)}
            />
        </Grid>
 
        <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
            <Grid item xs={12} style={{marginTop: "20px"}}>
                <Box display="flex" alignItems="center">
                    <Grid container>
                    <InputLabel>Հետազության նախնական օրը</InputLabel>

                        <Grid xs={12} item>
                            <Box>
                                {!firstDayChange ? 
                                <KeyboardDatePicker
                                    fullWidth
                                    margin="normal"
                                    format="MM/dd/yyyy"
                                    cancelLabel={"отмена"}
                                    okLabel={"Хорошо"}
                                    placeholder="ամիս/օր/տարի"
                                    value={firstDayOne}
                                    onChange={e => setFirstDayOne(e)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                : 
                                <div>
                                    <Grid container spacing={2}>
                                        <Grid xs={6} item>
                                            <KeyboardDatePicker
                                                fullWidth
                                                margin="normal"
                                                format="MM/dd/yyyy"
                                                cancelLabel={"отмена"}
                                                okLabel={"Хорошо"}
                                                placeholder="ամիս/օր/տարի"
                                                value={firstDayMultOne}
                                                onChange={e => setfirstDayMultOne(e)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>

                                        <Grid xs={6} item>
                                            <KeyboardDatePicker
                                                fullWidth
                                                margin="normal"
                                                format="MM/dd/yyyy"
                                                cancelLabel={"отмена"}
                                                okLabel={"Хорошо"}
                                                placeholder="ամիս/օր/տարի"
                                                value={firstDayMultTwo}
                                                onChange={e => setfirstDayMultTwo(e)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                }
                            </Box>
                        </Grid>

                        <Grid xs={12} item>
                            <Box>
                                <RepeatIcon 
                                    onClick={firstDayChangeFunc}
                                    style={{cursor: "pointer"}}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </MuiPickersUtilsProvider>

        <Grid item xs={12}>
            <InputLabel>Հետազության վերջնական օրը</InputLabel>

            <MuiPickersUtilsProvider utils={RuLocalizedUtils} locale={ruLocale}>
                    <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        format="MM/dd/yyyy"
                        cancelLabel={"отмена"}
                        okLabel={"Хорошо"}
                        placeholder="ամիս/օր/տարի"
                        value={finalDay}
                        onChange={e => setFinalDay(e)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
            </MuiPickersUtilsProvider>
        </Grid>
        
        <Grid item xs={12}>
            <TextField
                value={phoneInp}
                label="Հեռախոսահամար"
                onInput={e => setPhoneInp(e.target.value)}
                fullWidth
            />
        </Grid>
        
        <Grid item xs={12} style={{marginTop: "20px"}}>
            <Box display="flex" alignItems="center">
                <Grid container>                    
                    <Grid xs={12} item>
                        <InputLabel>ՈՒղեգրող բուժ հաստատություն </InputLabel>

                        <Box>
                            <TextField select value={clinic} onChange={e => setClinic(e.target.value)} fullWidth>
                                {allClinics.map((option, key) => <MenuItem key={key} value={option}>{option}</MenuItem>)}
                            </TextField>
                        </Box>
                    </Grid>

                    <Grid xs={12} item>
                        <Box display="flex" alignItems="center">
                            <p>{addClinic}</p>
                            {
                                !addClinic ? 
                                <AddCircleOutlineIcon
                                    onClick={() => setAddClinicModal(true)}
                                    cursor="pointer"
                                />
                                :
                                <EditIcon 
                                    onClick={() => setAddClinicModal(true)}
                                    style={{marginLeft: "5px", cursor: "pointer"}}
                                />
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Dialog open={addClinicModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Ավելացնել</DialogTitle>

                <DialogContent>
                    <TextField 
                        required
                        autoFocus
                        error={addClinicError === "error" ? true : false}
                        helperText={addClinicError === "error" ? "Լրացնել դաշտը" : ""}
                        margin="dense"
                        label="ՈՒղեգրող բուժ հաստատություն"
                        value={addClinic}
                        onInput={addClinicChange}
                        style={{width: 300}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeAddClinicModal(false)} color="primary">
                        Չեղարկել
                    </Button>
                    
                    <Button onClick={() => closeAddClinicModal(true)} color="primary">
                        Հաստատել
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>

        <Grid item xs={12} sm={12}>
            <TextField
                label="Բուժող բժիշկ"
                fullWidth
                onChange={e => setDoctorName(e.target.value)}
                value={doctorName}
            />
        </Grid>
    
        <Grid item xs={12} sm={12}>
            <TextField
                label="Հիվանդության տեսակ"
                fullWidth
                onChange={e => setTypeOfDisease(e.target.value)}
                value={typeOfDisease}
            />
        </Grid>

        <Grid item xs={12} style={{marginTop: "20px"}}>
            <InputLabel>Քաշ</InputLabel>
            <TextField
                type="weight"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setWeight(e.target.value)}
                value={weight}
            />
        </Grid>

        <Grid item xs={12}>
            <TextField
                type="email"
                label="Էլ. հասցե"
                fullWidth
                onChange={e => setEmail(e.target.value)}
                value={email}
            />
        </Grid>

        <Grid item xs={3} style={{marginTop: "20px"}}>
            <TextField
                select
                label="Դիսկի առկայություն"
                value={disc}
                onChange={e => setDisc(e.target.value)}
                fullWidth
            >
                <MenuItem key="yes" value="yes">Այո</MenuItem>
                <MenuItem key="no" value="no">Ոչ</MenuItem>
            </TextField>
        </Grid>

        <Grid item xs={3} style={{marginTop: "20px"}}>
            <TextField
                select
                label="Փաստաթղթերի առկայություն"
                value={documents}
                onChange={e => setDocuments(e.target.value)}
                fullWidth
            >
                <MenuItem key="yes" value="yes">Այո</MenuItem>
                <MenuItem key="no" value="no">Ոչ</MenuItem>
            </TextField>
        </Grid>

        <Grid item xs={3} style={{marginTop: "20px"}}>
                <TextField
                    select
                    label="Ստացման ձև"
                    value={received}
                    onChange={e => setReceived(e.target.value)}
                    fullWidth
                >
                
                <MenuItem key="email" value="email">Էլ հասցե</MenuItem>
                <MenuItem key="by_hand" value="by_hand">Առձեռն</MenuItem>
                <MenuItem key="-" value="-">-</MenuItem>
            </TextField>
        </Grid>

        <Grid item xs={3} style={{marginTop: "20px"}}>
                <TextField
                    select
                    label="Շաքարային Դիաբետ"
                    value={diabetes}
                    onChange={e => setDiabetes(e.target.value)}
                    fullWidth
                >
                
                <MenuItem key="yes" value="1">Այո</MenuItem>
                <MenuItem key="no" value="0">Ոչ</MenuItem>
            </TextField>
        </Grid>

        <Grid item xs={12} style={{marginTop: "20px"}}>
                <TextField
                    select
                    label="Պետպատվեր"
                    value={stateOrder}
                    onChange={e => setStateOrder(e.target.value)}
                    fullWidth
                >
                
                <MenuItem key="yes" value="yes">Այո</MenuItem>
                <MenuItem key="no" value="no">Ոչ</MenuItem>
            </TextField>
        </Grid>

        <Grid item xs={12}>
            <TextField
                type="number"
                value={visits}
                onChange={e => setVisits(e.target.value)}
                fullWidth
                label="Այցելության քանակը"
            />
        </Grid>

        <Grid item xs={12} style={{marginTop: "20px"}}>
            <TextField
                select
                label="Բարեգործություն"
                value={charity}
                onChange={e => setCharity(e.target.value)}
                fullWidth
            >
                <MenuItem key="yes" value="yes">Այո</MenuItem>
                <MenuItem key="no" value="no">Ոչ</MenuItem>
            </TextField>
        </Grid>

        <Grid item xs={12}>
            <InputLabel id="demo-mutiple-name-label">Նշումներ</InputLabel>

            <TextareaAutosize 
                aria-label="minimum height" 
                rowsMin={7}
                value={notes}
                onChange={e => setNotes(e.target.value)}
                style={{width: "100%", marginTop: "10px"}}
            />
        </Grid>

        <Grid item xs={12}>
            <Button variant="contained" disabled={uploadDisabled} component="label" startIcon={<CloudDownloadIcon />} fullWidth>
                Ներբեռնել
                <input type="file" hidden onChange={fileUpload} />
            </Button>
            
            {progress !== 0 ? <LinearProgressWithLabel value={progress} /> : null}

            {fileNameError === "error" ? <Typography color="error">Կրկին փորձեք կամ թարմացրեք էջը</Typography> : null}

            {fileName ? <Grid container>
                <Grid item>
                    <Typography variant="body1" gutterBottom>{fileName}</Typography>
                </Grid>

                <Grid item>
                    {!isDeleteFile ? <DeleteIcon cursor="pointer" onClick={deleteFile} /> : <CircularProgress style={{width: "20px", height: "20px"}} />} 
                </Grid>

                {fileDeleteError === "error" ? <Typography color="error">ֆայլը չի ջնջվում</Typography> : null}
            </Grid> : null}
        </Grid>
      
        <Grid item xs={12}>
            {
                !isLoadedDisable ? 
                    !isLoaded ? <Button variant="contained" color="primary" data-action='submit' onClick={sendToDB}>Հաստատել</Button>  : <CircularProgress />
                : null
            }
            {successMessage ? <Button variant="contained" style={{backgroundColor: "green", color: "#fff", marginBottom: "20px"}} startIcon={<CheckIcon />}>Ավելացումը կատարվել է հաջողությանբ</Button> : null}

            {errorMessage ? <Button variant="contained" color="secondary" style={{marginBottom: "20px"}} startIcon={<CancelIcon />}>
                {errorMessageText}
            </Button> : null}

        </Grid>
      </Grid>
    </React.Fragment>
  );
}