import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Title,
  Legend,
} from '@devexpress/dx-react-chart-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { Stack, Animation } from '@devexpress/dx-react-chart';

const legendStyles = () => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
});

const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);

const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);

const legendLabelStyles = () => ({
  label: {
    whiteSpace: 'nowrap',
  },
});

const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);

const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);

export default class CtChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount () {
        fetch(`${process.env.REACT_APP_API_URL}/report-ct-chart`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("appname_token"),
          },
        })
        .then(res => res.json())
        .then(res => this.setState({data: res}))
        .catch(err => console.log(err))
  }

  render() {
    return (
      <Paper style={{width: "100%", padding: "20px", marginTop: "20px"}}>
        <Chart data={this.state.data}>
          <ArgumentAxis />
          <ValueAxis />

          <BarSeries
            name="Ընդհանուր"
            valueField="total"
            argumentField="year"
            color="green"
          />
          <BarSeries
            name="Բարեգործություն"
            valueField="charity"
            argumentField="year"
            color="#0275d8"
          />
          <BarSeries
            name="Պետպատվեր"
            valueField="state_order"
            argumentField="year"
            color="#f0ad4e"
          />
          <Animation />
          <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
          <Title text="CT Դիագրամ" />
          <Stack />
        </Chart>
      </Paper>
    );
  }
}
