import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";

import Typography from '@material-ui/core/Typography';

//Icons
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import Alert from '@material-ui/lab/Alert';

//Import Icons
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from '@material-ui/icons/Edit';

import InfiniteScroll from "react-infinite-scroll-component";

function Ct() {
    const [patients, setPatients] = useState([]);
    const [isDeleted, setIsDeleted] = useState(false);

    const [isLoaded, setIsLoaded] = useState(false);

    const history = useHistory();

    const [searchValue, setSearchValue] = useState("");

    const [offset, setOffset] = useState(0);

    const [displayMore, setDisplayMore] = useState(false);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/ct-patient-all/${offset}/${10}`, {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(res => {
            setIsLoaded(true);
            setPatients(res);
        })
        .catch(err => console.log(err))
    }, [])

    const refreshData = () => {
        setPatients([]);
        setIsLoaded(false);

        fetch(`${process.env.REACT_APP_API_URL}/ct-patient-all/${offset}/${10}`, {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(res => {
            setIsLoaded(true);
            setPatients(res)
        })
        .catch(err => console.log(err))
    }

    const deleteItem = id => {
        if (window.confirm("Համոզված եք որ ուզում եք ջնջնել?")) {
            fetch(`${process.env.REACT_APP_API_URL}/ct-patient-delete/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + localStorage.getItem("appname_token")
                }
            })
            .then(res => res.json())
            .then(res => {
                if (res.status === "Success") {
                    refreshData();
                    
                    setIsDeleted(true);

                    setTimeout(() => {
                        setIsDeleted(false);
                    }, 3000)
                }
            })
            .catch(err => console.log(err))
        }
    }

    function dateFormat (date, yearShow) {
        if (date === '-') {
            return date;
        }
    
        var arr = date.split('-');
        var month = arr[1];
        var year = arr[0];
    
        if (month === '01') {
            month = "Հունվար";
        } else if (month === '02') {
            month = "Փետրվար";
        } else if (month === '03') {
            month = "Մարտ";
        } else if (month === '04') {
            month = "Ապրիլ";
        } else if (month === '05') {
            month = "Մայիս";
        } else if (month === '06') {
            month = "Հունիս";
        } else if (month === '07') {
            month = "Հուլիս";
        } else if (month === '08') {
            month = "Օգոստոս";
        } else if (month === '09') {
            month = "Սեպտեմբեր";
        } else if (month === '10') {
            month = "Հոկտեմբեր";
        } else if (month === '11') {
            month = "Նոյեմբեր";
        } else if (month === '12') {
            month = "Դեկտեմբեր";
        }
        
        if (yearShow === 'no') {
            return arr[2] + ' ' + month;
        } else {
            return arr[2] + ' ' + month + ', '+ year;
        }
    }

    const changeArchiveStatus = (id, e) => {
        if (e.target.checked) {
          e.currentTarget.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = "#8fd19e";
        } else {
          e.currentTarget.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = "#fff";
        }

        fetch(`${process.env.REACT_APP_API_URL}/ct-patient/checkArchive`, {
          method: "POST",
          body: JSON.stringify({id: id, status: e.target.checked}),
          headers: { "Content-type": "application/json; charset=UTF-8", "Authorization": "Bearer " + localStorage.getItem("appname_token")},
        })
    }

    const searchCt = val => {
        if (val === "Enter") {
            setIsLoaded(false);

            fetch(`${process.env.REACT_APP_API_URL}/ct-patient-search`, {
                method: "POST",
                body: JSON.stringify({searchVal: searchValue}),
                headers: {
                    "Content-type": "application/json; charset=UTF-8", 
                    "Authorization": "Bearer " + localStorage.getItem("appname_token")
                }
            })
            .then(res => res.json()) 
            .then(json => {
                setIsLoaded(true);
                setPatients(json);
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const loadMoreData = () => {
        setOffset(offset + 10);

        fetch(`${process.env.REACT_APP_API_URL}/ct-patient-all/${offset + 10}/${10}`, {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(res => !res.length ? setDisplayMore(true) : setPatients(prevArray => [...prevArray, ...res]))
        .catch(err => console.log(err))
    }

    return (
        <Grid item xs={12}>
            <div>
            <Typography variant="h4" gutterBottom>CT</Typography>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginBottom: "10px" }}
                        startIcon={<AddIcon />}
                        onClick={() => history.push("/ct-patients-create")}
                    >
                        Ավելացնել
                    </Button>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        id="standard-basic" 
                        label="Որոնել"
                        style={{marginBottom: "10px"}}
                        onChange={e => setSearchValue(e.target.value)}
                        onKeyDown={e => searchCt(e.key)}
                    />
                </Grid>
            </Grid>

            </div>

            {isDeleted ? <Alert variant="filled" severity="success">Տվյալը ջնջվել է հաջողությամբ</Alert> : null}

            <Paper>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>Անուն Ազգանուն</strong>
                            </TableCell>

                            <TableCell>
                                <strong>Հետազոտության օրը</strong>
                            </TableCell>

                            <TableCell>
                                <Tooltip title="Թարմացնել տվյալնները" aria-label="refreshdata">
                                    <RefreshIcon style={{ cursor: "pointer" }} onClick={refreshData} />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        
                    {!isLoaded ? <TableRow key={1}> <TableCell>
                                <CircularProgress />
                            </TableCell>
                        </TableRow> : patients.length ? patients.map((item, key) => {
                            return (
                                <TableRow key={key} style={{backgroundColor: item.diabetes === "yes" ? "rgb(255, 152, 0)" : item.status ? "rgb(143, 209, 158)" : "white"}}>
                                    <TableCell>{item.name_surname}</TableCell>
                                    <TableCell>{item.research_day ? dateFormat(item.research_day) : null}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Հաստատել և արխիվացնել" aria-label="archivedata">
                                            <Checkbox
                                                defaultChecked={item.status ? true : false}
                                                color="primary"
                                                onChange={e => changeArchiveStatus(item.id, e)}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => history.push(`/ct-patients-single/${item.id}`)}
                                            startIcon={<VisibilityIcon />}
                                        >
                                            Ավելին
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            onClick={() => history.push(`/ct-patients-edit/${item.id}`)}
                                            startIcon={<EditIcon />}
                                        >
                                            Փոխել
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ marginLeft: "40px" }}
                                            startIcon={<DeleteIcon />}
                                            onClick={() => deleteItem(item.id)}
                                        >
                                            Ջնջել
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }) : <TableRow key={1}>
                        <TableCell>Տվյալններ չկա</TableCell>
                    </TableRow>}
                    </TableBody>
                </Table>
            </Paper>

            {!displayMore ? <Button
                variant="contained"
                color="primary"
                style={{display: "flex", margin: "0 auto", marginTop: "20px"}}
                startIcon={<ExpandMoreIcon />}
                onClick={loadMoreData}
            >
                Բեռնել ավելին
            </Button> : null}
        </Grid>
    )
}

export default Ct;