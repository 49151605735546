import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

//Print js
import print from "print-js";

function CtReport () {
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(1);
    const [ctTotal, setCtTotal] = useState(0);
    const [ctCount, setCtCount] = useState(0);
    const [ctLoad, setCtLoad] = useState(false);
    const [ctMonthStateOrder, setCtMonthStateOrder] = useState(0);
    const [ctYearStateOrder, setCtYearStateOrder] = useState(0);
    const [ctYearCharity, setCtYearCharity] = useState(0);

    const [ctYearLoad, setCtYearLoad] = useState(false);

    const [yearFilterCount, setYearFilterCount] = useState(0);
    const [yearFilterTotal, setYearFilterTotal] = useState(0);

    const [yearForFilter, setYearForFilter] = useState(new Date().getFullYear());
    const [ctCharity, setCtCharity] = useState(0)

    const ctReportByYearFunc = () => {
        setCtYearLoad(true);

        fetch(`${process.env.REACT_APP_API_URL}/report-ct-year`, {
            method: "POST",
            body: JSON.stringify({year: yearForFilter}),
            headers: {
                "Content-type": "application/json; charset=UTF-8", 
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(json => {
            setCtYearCharity(json.charity)
            setCtYearStateOrder(json.state_order);
            setCtYearLoad(false)
            setYearFilterCount(json.patients)
            setYearFilterTotal(json.total)
        }).catch(err => console.log(err))
    }

    const ctReport = () => {
        setCtLoad(true)

        fetch(`${process.env.REACT_APP_API_URL}/report-ct`, {
            method: "POST",
            body: JSON.stringify({year: year, month: month}),
            headers: {
                "Content-type": "application/json; charset=UTF-8", 
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json()) 
        .then(json => {
            setCtMonthStateOrder(json.state_order);        
            setCtLoad(false)
            setCtTotal(json.total)
            setCtCount(json.patients)
            setCtCharity(json.charity)
        }).catch(err => console.log(err))
    }

    const formatMoney = n => (Math.round(n * 100) / 100).toLocaleString();

    const formatDateArm = month => {
        month =  "" + month + "";
        
        if (month === '1') {
            month = "Հունվար";
        } else if (month === '2') {
            month = "Փետրվար";
        } else if (month === '3') {
            month = "Մարտ";
        } else if (month === '4') {
            month = "Ապրիլ";
        } else if (month === '5') {
            month = "Մայիս";
        } else if (month === '6') {
            month = "Հունիս";
        } else if (month === '7') {
            month = "Հուլիս";
        } else if (month === '8') {
            month = "Օգոստոս";
        } else if (month === '9') {
            month = "Սեպտեմբեր";
        } else if (month === '10') {
            month = "Հոկտեմբեր";
        } else if (month === '11') {
            month = "Նոյեմբեր";
        } else if (month === '12') {
            month = "Դեկտեմբեր";
        }

        return month;
    }

    const ctPdfYearAndMonth = () => {
        const fileName = `Հաշվետվություն_CT_${formatDateArm(month) + "_" + year}`;

        fetch(`${process.env.REACT_APP_API_URL}/report-ct`, {
            method: "POST",
            body: JSON.stringify({year: year, month: month}),
            headers: {
                "Content-type": "application/json; charset=UTF-8", 
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(json => {
            document.title = fileName;

            json = [json];

            json[0].total = formatMoney(json[0].total) + " դրամ"

            print({
              printable: json,
              header: `CT Հաշվետվություն - ${formatDateArm(month)}, ${year}`,
              headerStyle: "text-align: center",
              properties: [
                { field: "total", displayName: "Ընդհանուր գումար" },
                { field: "state_order", displayName: "Պետպատվերների քանակ" },
                { field: "charity", displayName: "Բարեգործությունների քանակ" },
                { field: "patients", displayName: "Ընդհանուր քանակ" },
              ],
              documentTitle: "«Ռադիոիզոտոպների արտադրության կենտրոն» ՓԲԸ",
              type: "json",
              style: "table tr td {padding: 17px}",
              onPrintDialogClose: () => {
                document.title = "Registration Program";
              }
            });
        }).catch(err => console.log(err))
    }

    const ctPdfYear = () => {
        const fileName = `Հաշվետվություն_CT_${yearForFilter}`;

        fetch(`${process.env.REACT_APP_API_URL}/report-ct-year`, {
            method: "POST",
            body: JSON.stringify({year: yearForFilter}),
            headers: {
                "Content-type": "application/json; charset=UTF-8", 
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(json => {
            document.title = fileName;

            json = [json];

            json[0].total = formatMoney(json[0].total) + " դրամ"

            print({
              printable: json,
              header: `CT Հաշվետվություն - ${yearForFilter}`,
              headerStyle: "text-align: center",
              properties: [
                { field: "total", displayName: "Ընդհանուր գումար" },
                { field: "state_order", displayName: "Պետպատվերների քանակ" },
                { field: "charity", displayName: "Բարեգործությունների քանակ" },
                { field: "patients", displayName: "Ընդհանուր քանակ" },
              ],
              documentTitle: "«Ռադիոիզոտոպների արտադրության կենտրոն» ՓԲԸ",
              type: "json",
              style: "table tr td {padding: 17px}",
              onPrintDialogClose: () => {
                document.title = "Registration Program";
              }
            });
        }).catch(err => console.log(err))
    }

    return (
        <Paper style={{width: "100%", padding: "20px"}}>
                <Typography variant="h6">CT հետազոտության հաշվետվություն</Typography>
                
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Grid container>
                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <TextField
                                label="Տարի"
                                defaultValue={new Date().getFullYear()}
                                fullWidth
                                onChange={e => setYear(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <InputLabel>Ամիս</InputLabel>

                            <Box>
                                <TextField select value={month} onChange={e => setMonth(e.target.value)} fullWidth>
                                    <MenuItem value={1}>Հունվար</MenuItem>
                                    <MenuItem value={2}>Փետրվար</MenuItem>
                                    <MenuItem value={3}>Մարտ</MenuItem>
                                    <MenuItem value={4}>Ապրիլ</MenuItem>
                                    <MenuItem value={5}>Մայիս</MenuItem>
                                    <MenuItem value={6}>Հունիս</MenuItem>
                                    <MenuItem value={7}>Հուլիս</MenuItem>
                                    <MenuItem value={8}>Օգոստոս</MenuItem>
                                    <MenuItem value={9}>Սեպտեմբեր</MenuItem>
                                    <MenuItem value={10}>Հոկտեմբեր</MenuItem>
                                    <MenuItem value={11}>Նոյեմբեր</MenuItem>
                                    <MenuItem value={12}>Դեկտեմբեր</MenuItem>
                                </TextField>
                            </Box>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            {!ctLoad ? <Button variant="contained" color="primary" onClick={ctReport}>
                                Ցուցադրել
                            </Button> : <CircularProgress />}
                        </Grid>
                    </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Ընդհանուր գումար - {formatMoney(ctTotal)} դրամ</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Պետպատվերների քանակ - {ctMonthStateOrder}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Բարեգործությունների քանակ - {ctCharity}</p>
                        </Grid>

                        <Grid item xs={12}>
                            <p variant="h6">Ընդհանուր քանակ - {ctCount}</p>
                        </Grid>

                        <Grid>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<PictureAsPdfIcon />}
                                onClick={() => ctPdfYearAndMonth()}
                            >
                                Ներբեռնել PDF
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <Grid container>
                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <TextField
                                label="Տարի"
                                defaultValue={new Date().getFullYear()}
                                fullWidth
                                onChange={e => setYearForFilter(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            {!ctYearLoad ? <Button variant="contained" color="primary" onClick={ctReportByYearFunc}>
                                Ցուցադրել
                            </Button> : <CircularProgress />}
                        </Grid>
                    </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Ընդհանուր գումար - {formatMoney(yearFilterTotal)} դրամ</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Պետպատվերների քանակ - {ctYearStateOrder}</p>
                        </Grid>

                        <Grid item xs={12} style={{marginTop: "20px"}}>
                            <p variant="h6">Բարեգործությունների քանակ - {ctYearCharity}</p>
                        </Grid>

                        <Grid item xs={12}>
                            <p variant="h6">Ընդհանուր քանակ - {yearFilterCount}</p>
                        </Grid>
                        
                        <Grid>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<PictureAsPdfIcon />}
                                onClick={() => ctPdfYear()}
                            >
                                Ներբեռնել PDF
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Paper>
    )
}

export default CtReport;