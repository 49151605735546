import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

//Print js
import print from "print-js";

function SpectReport () {
    const [spectCtYear, setSpectCtYear] = useState(new Date().getFullYear());

    const [spectPetCtMonth, setSpectPetCtMonth] = useState("all");

    const [spectYearLoad, setSpectYearLoad] = useState(false);

    const [spectTotal, setSpectTotal] = useState(0);

    const [spectPatientsCount, setSpectPatientsCount] = useState(0);

    const [spectStateOrderCount, setSpectStateOrderCount] = useState(0);

    const [spectCharityCount, setSpectCharityCount] = useState(0);

    const [spectDate, setSpectDate] = useState({
        patients_count: 0,
        state_order: 0,
        total: 0,
        charity_count: 0
    })

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/report-spect`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("appname_token"),
            },
        })
        .then(res => res.json())
        .then(res => {
            setSpectCharityCount(res.charity);
            setSpectStateOrderCount(res.state_order);
            setSpectTotal(res.total);
            setSpectPatientsCount(res.patients_count);
        })
        .catch(err => console.log(err))
    }, [])

    const formatMoney = n => (Math.round(n * 100) / 100).toLocaleString();

    const formatDateArm = month => {
        month =  "" + month + "";
        
        if (month === '1') {
            month = "Հունվար";
        } else if (month === '2') {
            month = "Փետրվար";
        } else if (month === '3') {
            month = "Մարտ";
        } else if (month === '4') {
            month = "Ապրիլ";
        } else if (month === '5') {
            month = "Մայիս";
        } else if (month === '6') {
            month = "Հունիս";
        } else if (month === '7') {
            month = "Հուլիս";
        } else if (month === '8') {
            month = "Օգոստոս";
        } else if (month === '9') {
            month = "Սեպտեմբեր";
        } else if (month === '10') {
            month = "Հոկտեմբեր";
        } else if (month === '11') {
            month = "Նոյեմբեր";
        } else if (month === '12') {
            month = "Դեկտեմբեր";
        }

        return month;
    }

    const spectCtYearFunc = () => {
        setSpectYearLoad(true);

        fetch(`${process.env.REACT_APP_API_URL}/report-spect-year`, {
            method: "POST",
            body: JSON.stringify({year: spectCtYear, month: spectPetCtMonth}),
            headers: {
                "Content-type": "application/json; charset=UTF-8", 
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json()) 
        .then(json => {
            setSpectDate(json);
            setSpectYearLoad(false);
        }).catch(err => console.log(err))
    }

    const pdfGenerate = () => {
        const fileName = `Հաշվետվություն_SPECT/CT_${spectPetCtMonth !== "all" ? formatDateArm(spectPetCtMonth) + "_" + spectCtYear : spectCtYear}`;
   
        fetch(`${process.env.REACT_APP_API_URL}/report-spect-year`, {
            method: "POST",
            body: JSON.stringify({year: spectCtYear, month: spectPetCtMonth}),
            headers: {
                "Content-type": "application/json; charset=UTF-8", 
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(json => {
            document.title = fileName;

            json = [json];

            json[0].total = formatMoney(json[0].total)

            print({
              printable: json,
              header: `SPECT/CT Հաշվետվություն - ${spectPetCtMonth !== "all" ? formatDateArm(spectPetCtMonth) + ", " + spectCtYear : spectCtYear}`,
              headerStyle: "text-align: center",
              properties: [
                { field: "patients_count", displayName: "Ընդհանուր հիվանդների քանակ" },
                { field: "state_order", displayName: "Պետպատվերների քանակ" },
                { field: "charity_count", displayName: "Բարեգործությունների քանակ" },
                { field: "total", displayName: "Ընդհանուր գումար" }
              ],
              documentTitle: "«Ռադիոիզոտոպների արտադրության կենտրոն» ՓԲԸ",
              type: "json",
              style: "table tr td {padding: 17px}",
              onPrintDialogClose: () => {
                document.title = "Registration Program";
              }
            });
        }).catch(err => console.log(err))
    }

    return (
        <Paper style={{width: "100%", padding: "20px", marginTop: "20px"}}>
                <Typography variant="h6">SPECT/CT հետազոտության հաշվետվություն</Typography>

                <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <TextField
                            label="Տարի"
                            defaultValue={new Date().getFullYear()}
                            fullWidth
                            onChange={e => setSpectCtYear(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <InputLabel>Ամիս</InputLabel>

                        <Box>
                            <TextField select value={spectPetCtMonth} onChange={e => setSpectPetCtMonth(e.target.value)} fullWidth>
                                <MenuItem value={"all"}>Բոլոր ամիսներ</MenuItem>
                                <MenuItem value={1}>Հունվար</MenuItem>
                                <MenuItem value={2}>Փետրվար</MenuItem>
                                <MenuItem value={3}>Մարտ</MenuItem>
                                <MenuItem value={4}>Ապրիլ</MenuItem>
                                <MenuItem value={5}>Մայիս</MenuItem>
                                <MenuItem value={6}>Հունիս</MenuItem>
                                <MenuItem value={7}>Հուլիս</MenuItem>
                                <MenuItem value={8}>Օգոստոս</MenuItem>
                                <MenuItem value={9}>Սեպտեմբեր</MenuItem>
                                <MenuItem value={10}>Հոկտեմբեր</MenuItem>
                                <MenuItem value={11}>Նոյեմբեր</MenuItem>
                                <MenuItem value={12}>Դեկտեմբեր</MenuItem>
                            </TextField>
                        </Box>
                    </Grid>

                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        {!spectYearLoad ? <Button variant="contained" color="primary" onClick={spectCtYearFunc}>
                            Ցուցադրել
                        </Button> : <CircularProgress />}
                    </Grid>

                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <p variant="h6">Ընդհանուր հիվանդների քանակ - {spectDate.patients_count}</p>
                    </Grid>

                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <p variant="h6">Պետպատվերների քանակ - {spectDate.state_order}</p>
                    </Grid>

                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <p variant="h6">Բարեգործությունների քանակ - {spectDate.charity_count}</p>
                    </Grid>

                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <p variant="h6">Ընդհանուր գումար - {formatMoney(spectDate.total)} դրամ</p>
                    </Grid>

                    <Grid>
                        <Button
                            variant="contained"
                            color="default"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() => pdfGenerate()}
                        >
                            Ներբեռնել PDF
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <p variant="h6">Ընդհանուր հիվանդների քանակ - {spectPatientsCount}</p>
                    </Grid>

                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <p variant="h6">Պետպատվերների քանակ - {spectStateOrderCount}</p>
                    </Grid>

                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <p variant="h6">Բարեգործությունների քանակ - {spectCharityCount}</p>
                    </Grid>

                    <Grid item xs={12} style={{marginTop: "20px"}}>
                        <p variant="h6">Ընդհանուր գումար - {formatMoney(spectTotal)} դրամ</p>
                    </Grid>
                </Grid>
                </Grid>
            </Paper>
    )
}

export default SpectReport;