import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

//Icons
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SaveIcon from "@material-ui/icons/Save";

//Ckeditor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(true);
  const [editorData, setEditorData] = React.useState(null);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isDataFull, setIsDataFull] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const editorConfiguration = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "bulletedList",
        "numberedList",
        "|",
        "indent",
        "outdent",
        "|",
        "blockQuote",
        "undo",
        "redo",
        "fontSize",
        "fontBackgroundColor",
        "fontColor",
        "fontFamily",
      ],
    },
    language: "en",
    image: {
      toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableCellProperties",
        "tableProperties",
      ],
    },
  };

  const handleClose = () => {
    setOpen(false);
    props.openForm(false);
  };

  const handleApprove = () => {
    if (!editorData) {
      setIsDataFull(true);
    } else {
      setIsDataFull(false);
      setLoader(true);

      if (window.confirm("Ուղարկել")) {
        fetch(`${process.env.REACT_APP_API_URL}/send/emails`, {
          method: "POST",
          body: JSON.stringify({
            editorData: editorData,
            emails: props.emailsCollection,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("appname_token"),
          },
        })
          .then(res => res.json())
          .then(res => {            
            setLoader(false);

            if (res.status === "Success") {
              setIsSuccess(true);

              setTimeout(() => {
                setIsSuccess(false);
              }, 2500);
            }
          })
          .catch((err) => {
            setLoader(false);
            console.log(err);
          });
      } else {
        setLoader(false);
      }
    }
  };

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">Նամակի բովանդակություն</DialogTitle>
      <DialogContent>
        <CKEditor
          editor={ ClassicEditor }
          config={editorConfiguration}
          data=""
          onReady={() => setIsDisabled(false)}
          disabled={isDisabled}
          onChange={(event, editor) => {
            setEditorData(editor.getData());
            setIsDataFull(false);
          }}
        />

        {isDataFull ? (
          <Typography variant="subtitle1" color="secondary" gutterBottom>
            Դաշտը պարտադիր է
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        {loader ? (
          "Loading ..."
        ) : (
          <div>
            {isSuccess ? (
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ marginTop: "6px" }}
                gutterBottom
              >
                Նամակը ուղարկվել է հաջողությամբ
              </Typography>
            ) : null}
        
            <Button
              onClick={handleClose}
              color="secondary"
              variant="contained"
              startIcon={<HighlightOffIcon />}
            >
              Փակել
            </Button>

            <Button
              onClick={handleApprove}
              color="primary"
              variant="contained"
              style={{ marginLeft: "10px" }}
              startIcon={<SaveIcon />}
            >
              Հաստատել
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
