import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from "react-router";

//Import Icons
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

class SpectSingle extends React.Component {
    constructor () {
        super();

        this.state = {
            data: null,
            files: null,
            isLoaded: false,
            deleteErrorMessage: false,
            deleteSuccessMessage: false,
            sheetName: null,
        }

        this.deleteItem = this.deleteItem.bind(this)
        this.deleteFile = this.deleteFile.bind(this)
    }

    componentDidMount () {        
        const { match: { params } } = this.props;

        fetch(`${process.env.REACT_APP_API_URL}/spect-single/${params.id}`, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("appname_token")
            }
        })
        .then(res => res.json())
        .then(res => {
            if (res.status === "Data not found") {
                window.location.href = "/spect-patients";
            }

            this.setState({data: res.data, files: res.files, isLoaded: true});
        }).catch(err => {
            console.log(err);
        })
    }
    
    deleteItem () {
        let conf = window.confirm("Համոզված եք որ ուզում եք ջնջել");
        
        if (conf) {
            fetch(`${process.env.REACT_APP_API_URL}/delete-spect/${this.props.match.params.id}`, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("appname_token")
                }
            })
            .then(res => res.json())
            .then(data => {
                if (data.status === "Success") {
                    this.setState({deleteSuccessMessage: true});

                    setTimeout(() => {
                        this.setState({deleteSuccessMessage: false});
                    }, 2000);
                } else {
                    this.setState({deleteErrorMessage: true});
                    
                    setTimeout(() => {
                        this.setState({deleteErrorMessage: false})
                    }, 2000);
                }
            })
            .catch(err => console.log(err))
        }
    }

    deleteFile (id) {
        if (window.confirm("Համոզված եք որ ուզում եք ջնջել")) {
            fetch(`${process.env.REACT_APP_API_URL}/file-id-delete/${id}`, {
                method: 'DELETE',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("appname_token")
                }
            }).then(response => response.json())
            .then(res => {
                if (res.status === "Success") {
                    let items = this.state.files.filter(item => item.id !== id);
                    
                    this.setState({files: items});
                }
            })
            .catch(error => console.error(error))
        }
    }

    render () {
        function convertPhoneNumber (phone) {
            if (phone === "" || phone === "-" || phone === undefined) {
                return "Տվյալը բացակայում է";
            } else {
                return phone;
            }
        }

        function dateFormat (date, yearShow) {
            if (date === '-') {
                return date;
            }
        
            var arr = date.split('-');
            var month = arr[1];
            var year = arr[0];
        
            if (month === '01') {
                month = "Հունվար";
            } else if (month === '02') {
                month = "Փետրվար";
            } else if (month === '03') {
                month = "Մարտ";
            } else if (month === '04') {
                month = "Ապրիլ";
            } else if (month === '05') {
                month = "Մայիս";
            } else if (month === '06') {
                month = "Հունիս";
            } else if (month === '07') {
                month = "Հուլիս";
            } else if (month === '08') {
                month = "Օգոստոս";
            } else if (month === '09') {
                month = "Սեպտեմբեր";
            } else if (month === '10') {
                month = "Հոկտեմբեր";
            } else if (month === '11') {
                month = "Նոյեմբեր";
            } else if (month === '12') {
                month = "Դեկտեմբեր";
            }
            
            if (yearShow === 'no') {
                return arr[2] + ' ' + month;
            } else {
                return arr[2] + ' ' + month + ', '+ year;
            }
        }

        function dateCheck (date) {
            if (date === '-') {
                return date;
            }
            
            if (date) {
                if (date.includes("/")) {
                    let split = date.split('/');
                    return dateFormat(split[0], 'no') + ' մինչև ' + dateFormat(split[1], 'no');
                } else {
                    return dateFormat(date);
                }
            }
        }

        function setCreatedAt (date) {
            date = new Date(date);
            let month = date.getMonth();

            if (month === 12) {
                month = "Հունվար";
            } else if (month === 1) {
                month = "Փետրվար";
            } else if (month === 2) {
                month = "Մարտ";
            } else if (month === 3) {
                month = "Ապրիլ";
            } else if (month === 4) {
                month = "Մայիս";
            } else if (month === 5) {
                month = "Հունիս";
            } else if (month === 6) {
                month = "Հուլիս";
            } else if (month === 7) {
                month = "Օգոստոս";
            } else if (month === 8) {
                month = "Սեպտեմբեր";
            } else if (month === 9) {
                month = "Հոկտեմբեր";
            } else if (month === 10) {
                month = "Նոյեմբեր";
            } else if (month === 11) {
                month = "Դեկտեմբեր";
            }

            return date.getDate() + " " + month + ", " + date.getFullYear() + " ժամը " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        }

        function firstDayPrintFunc (date) {
            if (date === "-") {
                return "Տվյալը բացակայում է";
            } else if (date.includes(null)) {
                return "Տվյալը բացակայում է";
            } else {
                return dateCheck(date);
            }
        }

        function cutString (str) {
            if (str.length > 45) {
                let arr = str.split(".");
                let ext = arr[arr.length - 1];
                arr.pop();
                
                return arr.join().slice(0, 44) + "..." + ext;
            } else {
                return str;
            }
        }

        if (!this.state.isLoaded) {
            return <div><CircularProgress /></div>;
        } else {
            return (
                <div>
                    {this.state.deleteErrorMessage ? <Button variant="contained" color="secondary">Փորձեք կրկին</Button> : null}

                    {this.state.deleteSuccessMessage ? <Button variant="contained" style={{backgroundColor: "green", color: "#fff"}}>
                        Տվյալը ջնջվել է հաջողությամբ
                    </Button> : null}
                    <List>
                        <KeyboardBackspaceIcon onClick={() => this.props.history.push("/spect-patients")} style={{cursor: "pointer"}} />

                        <ListItem>
                            <Grid container>
                                <Grid sm={4} item>
                                    <Button 
                                        variant="contained"
                                        onClick={() => this.props.history.push("/spect-edit/" + this.state.data.id)}
                                    >
                                    Փոխել
                                    </Button>
                                </Grid>

                                {!this.state.data.is_deleted ? <Grid sm={8} item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{marginLeft: "40px"}}
                                        startIcon={<DeleteIcon />}
                                        onClick={this.deleteItem}
                                    >
                                    Ջնջել
                                    </Button>
                                </Grid> : null}
                                
                            </Grid>
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Անուն Ազգանուն" 
                            secondary={this.state.data.name_surname === "-" ? "Տվյալը բացակայում է" : this.state.data.name_surname} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Հետազոտության նախնական օրը" 
                                secondary={firstDayPrintFunc(this.state.data.first_day)} 
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Հետազության վերջնական օրը" 
                                secondary={firstDayPrintFunc(this.state.data.final_day)} 
                            />
                        </ListItem>
                        
                        <ListItem>
                            <ListItemText primary="Հեռախոսահամար" secondary={convertPhoneNumber(this.state.data.phone)} />
                        </ListItem>

                        <ListItem>
                            <ListItemText 
                                primary="ՈՒղեգրող բուժ հաստատություն" 
                                secondary={this.state.data.medical === "-" ? 'Տվյալը բացակայում է' : this.state.data.medical} 
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText 
                                primary="ՈՒղեգրող բուժ հաստատություն" 
                                secondary={this.state.data.medical === "-" ? 'Տվյալը բացակայում է' : this.state.data.medical} 
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="SPECT/CT տեսակ" 
                            secondary={this.state.data.spect_type} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Հիվանդության տեսակ" 
                            secondary={this.state.data.type_of_disease === "-" ? 'Տվյալը բացակայում է' : this.state.data.type_of_disease} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Դիսկ Առկայություն" 
                            secondary={this.state.data.disc === 'yes' ? 'Այո' : 'Ոչ'} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Ստացման ձև" 
                            secondary={this.state.data.received === '-' ? 'Տվյալը բացակայում է' : this.state.data.received === 'email' ? 'Էլ.փոստ' : 'Առձեռն'} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Փաստաթղթերի առկայություն" 
                            secondary={this.state.data.documents === "yes" ? 'Այո' : 'Ոչ'} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Շաքարային Դիաբետ" 
                            secondary={this.state.data.diabetes === 1 ? 'Այո' : 'Ոչ'} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Պետպատվեր" 
                            secondary={this.state.data.state_order === "yes" ? 'Այո' : 'Ոչ'} />
                        </ListItem>
                        
                        <ListItem>
                            <ListItemText primary="Քաշ" secondary={this.state.data.weight} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Էլ. հասցե" 
                            secondary={this.state.data.email === '-' ? 'Հիվանդը չի ներկայացրել email' : this.state.data.email} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Բարեգործություն" secondary={this.state.data.charity === 'yes' ? 'Այո' : "Ոչ"} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Այցելության քանակը" secondary={this.state.data.visits} />
                        </ListItem>

                        <ListItem>
                            <ListItemText primary="Նշումներ" 
                            secondary={this.state.data.notes === '-' ? 'Տվյալը բացակայում է' : this.state.data.notes} />
                        </ListItem>

                        {this.state.data.file_name === "-" ? 
                            <ListItem>
                                <ListItemText 
                                    primary="Լրացված թերթիկ"
                                    secondary={"Տվյալը բացակայում է"} 
                                />
                            </ListItem>
                        :   <a
                                href={`${process.env.REACT_APP_STORAGE_URL}/spect_files/${this.state.data.file_name}`}
                                target="_blank"
                            >
                                <ListItem>
                                    <ListItemText
                                        primary="Լրացված թերթիկ"
                                        secondary={this.state.data.file_name} 
                                    />
                                </ListItem>
                            </a>
                        }
                    </List>

                    <List>
                        <ListItem>
                            <ListItemText primary="Գրանցման օրը"
                            secondary={setCreatedAt(this.state.data.created_at)} />
                        </ListItem>

                        {this.state.data.created_at !== this.state.data.updated_at ? 
                            <ListItem>
                                <ListItemText primary="Փոփոխման օրը"
                                secondary={setCreatedAt(this.state.data.updated_at)} />
                            </ListItem>
                        : null}

                        <ListItem>
                            <ListItemText primary="Գրանցում կատարող"
                            secondary={this.state.data.user_name === null ? "Չկա" : this.state.data.user_name} />
                        </ListItem>
                    </List>
                </div>
            )
        }
    }
}

export default withRouter(SpectSingle);