import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Նախագծող՝ '}
        <Link rel="noopener" color="inherit" target="_blank" href="https://narek-webdev.github.io/site/">
          https://narek-webdev.github.io
        </Link>
      </Typography>
    );
}

export default Copyright;