import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListItemText from "@material-ui/core/ListItemText";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function SignUp() {
  const [open, setOpen] = useState(false);
  const [isLoaded, seIsLoaded] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => setOpen(false);

  const logOut = () => {
    seIsLoaded(true);

        fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
          method: "POST",
          headers: {"Content-type": "application/json; charset=UTF-8", "Authorization": "Bearer " + localStorage.getItem("appname_token")}
        })
          .then(res => res.json())
          .then(res => {
            if (res.status === "Token is Invalid") {
              seIsLoaded(false);
            }

            if (res.message === "Successfully logged out") {
              window.location.reload();
              localStorage.removeItem("appname_token");
              localStorage.removeItem("user_name");

              seIsLoaded(false);
              setOpen(false);
            } else {
              seIsLoaded(false);
            }
          })
          .catch(err => {
            console.error(err);
            seIsLoaded(false);
          });
  };

  return (
    <ListItem button>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>

      <ListItemText primary="Դուրս գալ" onClick={() => setOpen(true)} />

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Համոզված եք որ ուզում եք դուրս գալ"}
        </DialogTitle>

        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Ոչ
          </Button>

          {!isLoaded ? (
            <Button onClick={logOut} color="primary" autoFocus>
              Այո
            </Button>
          ) : (
            <CircularProgress />
          )}
        </DialogActions>
      </Dialog>
    </ListItem>
  );
}
