import SpectReport from './SpectReport';
import PetCtReport from './PetCtReport';
import CtReport from './CtReport';
import PetCtChart from './PetCtChart';
import CtChart from './CtChart';
import SpectCtChart from './SpectCtChart';

function Report() {
    return (
        <>
            {/* Ct */}
            <CtReport />
            <CtChart />

            {/* Pet Ct */}
            <PetCtReport />
            <PetCtChart />
          
            {/* Spect/Ct Ct */}
            <SpectReport />
            <SpectCtChart />
        </>
    )
}

export default Report;