import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

import EmailDialog from "./EmailDialog";

class Email extends React.Component {
  constructor() {
    super();

    this.state = {
      data: null,
      isLoaded: false,
      dialogOpen: false,
      emailsCollection: null,
      checkAllState: false,
      emailsSum: 0
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/all-emails`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("appname_token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        let checkSpecialSymbols = res.filter((row) => {
          if (
            (row.email.split("@").length - 1 > 1 && row.email.includes(",")) ||
            row.email.split("@").length - 1 === 1
          ) {
            return row;
          }
        });

        checkSpecialSymbols.map((row) => {
          if (row.email.split("@").length - 1 > 1 && row.email.includes(",")) {
            checkSpecialSymbols[checkSpecialSymbols.indexOf(row)] = "";
            row.email = row.email.split(",");

            row.email.map((singleEmail) => {
              singleEmail = singleEmail.trim();

              checkSpecialSymbols.push({
                id: row.id,
                name_surname: row.name_surname,
                email: singleEmail,
              });
            });
          }
        });

        let removeEmptyItems = checkSpecialSymbols.filter((item) => {
          if (item !== "") {
            item.checked = false;

            return item;
          }
        });

        this.setState({ data: removeEmptyItems, isLoaded: true, emailsSum: removeEmptyItems.length });
      })

      .catch((err) => console.log(err));
  }

  render() {
    const changeCheckboxValue = (checked, id) => {
      if (!checked && this.state.checkAllState) {
        this.setState({ checkAllState: false });
      }

      let checkedMap = this.state.data.map((item) => {
        if (item.id === id) {
          if (item.checked) {
            item.checked = false;
          } else {
            item.checked = true;
          }
        }

        return item;
      });

      this.setState({ data: checkedMap });
    };

    const setData = (data) => {
      return !data.length ? (
        <TableRow key={1}>
          <TableCell>Տվյալններ չկա</TableCell>
        </TableRow>
      ) : (
        data.map((row, key) => (
          <TableRow
            key={key}
            style={{
              backgroundColor: row.checked ? "rgb(224 224 224 / 29%)" : "#fff",
            }}
          >
            <TableCell>{row.name_surname}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                href={`/more/` + row.id}
              >
                Ավելին
              </Button>
            </TableCell>
            <TableCell>
              <Checkbox
                onChange={(e) => changeCheckboxValue(e.target.checked, row.id)}
                checked={row.checked}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </TableCell>
          </TableRow>
        ))
      );
    };

    const checkAll = (e) => {
      this.setState({ checkAllState: e });

      let checkedMap = this.state.data.map((item) => {
        e ? (item.checked = true) : (item.checked = false);
        return item;
      });

      this.setState({ data: checkedMap });
    };

    const openDialog = () => {
      let filteredData = this.state.data.filter((item) => {
        return item.checked;
      });

      let getAllEmails = filteredData.map((item) => {
        return item.email;
      });

      if (getAllEmails.length) {
        this.setState({ dialogOpen: true, emailsCollection: getAllEmails });
      }
    };

    const openEmailForm = (val) => {
      this.setState({ dialogOpen: val });
    };

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: "10px" }}
            onClick={openDialog}
          >
            Գրել
          </Button>

          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Անուն Ազգանուն</strong>
                  </TableCell>

                  <TableCell>
                    <strong>Էլ․փոստ</strong>
                  </TableCell>

                  <TableCell></TableCell>

                  <TableCell>
                    <Checkbox
                      checked={this.state.checkAllState}
                      onChange={(e) => checkAll(e.target.checked)}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    /> {this.state.emailsSum}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.state.isLoaded ? (
                  setData(this.state.data)
                ) : (
                  <TableRow key={1}>
                    <TableCell>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        {this.state.dialogOpen ? (
          <EmailDialog
            openForm={openEmailForm}
            emailsCollection={this.state.emailsCollection}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default Email;
