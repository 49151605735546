import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import dateCheck from "../usefulFunction/DateCheck";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

//Icons
import PrintIcon from "@material-ui/icons/Print";
import CheckIcon from "@material-ui/icons/Check";
import BackupIcon from "@material-ui/icons/Backup";
import CancelIcon from "@material-ui/icons/Cancel";
import RefreshIcon from '@material-ui/icons/Refresh';

//Upload js
import Upload from "../FileUpload/Upload";

//Print js
import print from "print-js";

// import 'date-fns';
import CircularProgress from "@material-ui/core/CircularProgress";

import Tooltip from '@material-ui/core/Tooltip';

import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12.4px",
      }
    }
  }
});

class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoaded: false,
      dataFilter: "",
      changeDateFilter: false,
      firstDateFilter: new Date(),
      checkAllSwitch: false,
      archiveLoader: false,
      archiveSuccess: false,
      printIds: [],
      uploadFormOpen: false,
      userId: 0,
    };

    this.archiveStatus = this.archiveStatus.bind(this);
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/data`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("appname_token"),
      },
    })
      .then(res => res.json())
      .then(res => {          
        res.data.map(item => {
          return (item.print = false);
        });

        this.setState({
          data: res.data,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
  }

  archiveStatus() {
    if (this.state.archiveSuccess) {
      setTimeout(() => {
        this.setState({ archiveSuccess: false });
      }, 2500);
    }

    return this.state.archiveSuccess ? (
      <Button
        variant="contained"
        color="secondary"
        style={{ backgroundColor: "green", marginTop: "20px" }}
        startIcon={<CheckIcon />}
      >
        Տվյալը արխիվացվել է հաջողությանբ
      </Button>
    ) : (
      ""
    );
  }

  openUploadForm = val => {
    this.setState({ uploadFormOpen: val });
  };

  render() {
    let that = this;

    const changeFilteredData = e => {
      this.setState({
        dataFilter: e.target.value,
        isLoaded: false,
      });

      fetch(`${process.env.REACT_APP_API_URL}/approved`, {
        method: "POST",
        body: JSON.stringify({ data: e.target.value }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("appname_token"),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          res.map((item) => {
            return (item.print = false);
          });

          this.setState({ data: res, isLoaded: true });
        })
        .catch((err) => console.log(err));
    };

    const changeRejectedStatus = (id) => {
      let filtItem = this.state.data.filter((item) => {
        return item.id !== id;
      });

      this.setState({ data: filtItem });

      fetch(`${process.env.REACT_APP_API_URL}/data/reject`, {
        method: "POST",
        body: JSON.stringify({ id: id }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("appname_token"),
        },
      });
    };

    const changePrintValue = (id, e) => {
      this.state.data.map((item) => {
        return item.id === id ? (item.print = item.print ? false : true) : null;
      });

      if (e.target.checked) {
        this.setState({ printIds: [...this.state.printIds, id] });
      } else {
        let removeArray = this.state.printIds.filter((item) => {
          return item !== id;
        });

        this.setState({ printIds: removeArray });
      }

      this.setState({ data: this.state.data });
    };

    const printFunc = () => {
      if (this.state.printIds.length) {
        fetch(`${process.env.REACT_APP_API_URL}/print`, {
          method: "POST",
          body: JSON.stringify({ searchIDs: this.state.printIds }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("appname_token"),
          },
        })
          .then((res) => res.json())
          .then((res) => {
            res.map((item) => {
              item.phone = item.phone === "-" ? " " : item.phone;
              item.date_of_birth = "";
              item.notes = "";
              item.type_of_disease = item.type_of_disease === "-" ? " " : item.type_of_disease;
              item.weight = item.weight === 0 ? " " : item.weight + " / ";
              item.diabetes = !item.diabetes ? "Ոչ" : "Այո";

              return item;
            });

            print({
              printable: res,
              header: "Pet/Ct Armenia",
              headerStyle: "text-align: center",
              properties: [
                { field: "name_surname", displayName: "Անուն Ազգանուն" },
                { field: "date_of_birth", displayName: "Ծննդյան օր" },
                { field: "phone", displayName: "Հեռախոսահամար" },
                { field: "type_of_disease", displayName: "Հիվանդության տեսակ" },
                { field: "weight", displayName: "Քաշ / Հասակ" },
                { field: "notes", displayName: "Նշումներ" },
                { field: "diabetes", displayName: "Շաքարային Դիաբետ"}
              ],
              documentTitle: "",
              type: "json",
              style: "table tr td {padding: 17px}",
            });
          });
      }
    };

    const changeArchiveStatus = (id, e) => {
      if (e.target.checked) {
        e.currentTarget.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = "#8fd19e";
      } else {
        e.currentTarget.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = "#fff";
      }

      fetch(`${process.env.REACT_APP_API_URL}/data/checkArchive`, {
        method: "POST",
        body: JSON.stringify({id: id, status: e.target.checked}),
        headers: { "Content-type": "application/json; charset=UTF-8", "Authorization": "Bearer " + localStorage.getItem("appname_token")},
      })
    }

    const refreshAllData = () => {
      this.setState({isLoaded: false});

      fetch(`${process.env.REACT_APP_API_URL}/data`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("appname_token"),
        },
      })
      .then(res => res.json())
      .then(res => {
        res.data.map(item => {
          return (item.print = false);
        });
  
        this.setState({
          data: res.data,
          isLoaded: true,
        });
      })
      .catch(err => console.log(err));
    }

    function setData(data, archiveLoader) {
      return !data.length ? (
        <TableRow key={1}>
          <TableCell>Տվյալններ չկա</TableCell>
        </TableRow>
      ) : (
        data.map(row => {
          return (
            <MuiThemeProvider key={row.id} theme={theme}>
            <TableRow style={{ backgroundColor: row.archive ? "#8fd19e" : row.diabetes ? "rgb(255, 152, 0)" : "white" }}>
              <TableCell>
                <Checkbox
                  checked={row.print}
                  onChange={(e) => changePrintValue(row.id, e)}
                  color="primary"
                />
              </TableCell>

              <TableCell>{row.name_surname}</TableCell>
              
              <TableCell>{dateCheck(row.first_day)}</TableCell>
              
              <TableCell>{row.type_of_disease}</TableCell>
              
              <TableCell>{row.notes}</TableCell>

              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  href={`/more/` + row.id}
                >
                  Ավելին
                </Button>
              </TableCell>

              {!row.rejected ? (
                <TableCell>
                  <Tooltip title="Հաստատել և արխիվացնել" aria-label="archivedata">
                    <Checkbox
                      defaultChecked={false}
                      color="primary"
                      onChange={e => changeArchiveStatus(row.id, e)}
                    />
                  </Tooltip>
                </TableCell>
              ) : null}

              <TableCell>
                <Tooltip title={!row.rejected ? "Հրաժարվել" : "Հանել հրաժարվածների ցանկից"} aria-label="reject">
                  <CancelIcon cursor="pointer" onClick={() => changeRejectedStatus(row.id)}/>
                </Tooltip>
              </TableCell>

              <TableCell>
                <Tooltip title="Կցել ֆայլեր" aria-label="fileUpload">
                  <BackupIcon
                    onClick={() =>
                      that.setState({
                        uploadFormOpen: true,
                        userId: row.id,
                      })
                    }
                    cursor="pointer"
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
            </MuiThemeProvider>
          );
        })
      );
    }

    return (
      <React.Fragment>
        {this.state.uploadFormOpen ? (
          <Upload openForm={this.openUploadForm} userId={this.state.userId} />
        ) : null}

        <div>{this.archiveStatus()}</div>

        <Grid item xs={12}>
          <FormControl style={{ width: "300px", marginBottom: "10px" }}>
                <InputLabel id="demo-simple-select-label">
                  Ֆիլտրել տվյալնները
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.dataFilter}
                  onChange={changeFilteredData}
                >
                  <MenuItem value="all">Բոլոր տվյալնները</MenuItem>
                  <MenuItem value="rejected">Հրաժարված տվյալններ</MenuItem>
                  <MenuItem value="half_done">Կիսատ տվյալններ</MenuItem>
                </Select>
            </FormControl>

          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Tooltip title="Տպել տվյալնները" aria-label="fileUpload">
                      <PrintIcon onClick={printFunc} style={{ cursor: "pointer" }} />
                    </Tooltip>
                  </TableCell>

                  <TableCell>
                    <strong>Անուն Ազգանուն</strong>
                  </TableCell>

                  <TableCell>
                    <strong>Հետազոտության նախնական օրը</strong>
                  </TableCell>

                  <TableCell>
                    <strong>Հիվանդության տեսակ</strong>
                  </TableCell>

                  <TableCell>
                    <strong>Նշումներ</strong>
                  </TableCell>

                  <TableCell>
                    <Tooltip title="Թարմացնել տվյալնները" aria-label="refreshdata">
                      <RefreshIcon style={{cursor: "pointer"}} onClick={refreshAllData} />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!this.state.isLoaded ? (
                  <TableRow key={1}>
                    <TableCell>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  setData(this.state.data, this.state.archiveLoader)
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Orders;
