import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import OtherInformation from "./coreData/OtherInformation";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";

//Icons
import AddIcon from "@material-ui/icons/Add";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import ArchiveIcon from "@material-ui/icons/Archive";
import DeleteIcon from "@material-ui/icons/Delete";
import PhoneIcon from '@material-ui/icons/Phone';
import PersonIcon from '@material-ui/icons/Person';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AssessmentIcon from '@material-ui/icons/Assessment';

//Logout
import Logout from "./Auth/Logout";

let url = window.location.href.split("/");
let sub_url = url[url.length - 2];
url = url[url.length - 1];

function MainListItems() {
  return (
    <React.Fragment>
      <Divider />

      <a href="/">
        <ListItem button selected={url === "" ? true : false}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>

          <ListItemText primary="Գլխավոր" />
        </ListItem>
      </a>

      <a href="/create">
        <ListItem button selected={url === "create" ? true : false}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>

          <ListItemText primary="Ավելացնել" />
        </ListItem>
      </a>

      <OtherInformation />

      <a href="/archive">
        <ListItem button selected={url === "archive" ? true : false}>
          <ListItemIcon>
            <ArchiveIcon />
          </ListItemIcon>
          <ListItemText primary="Արխիվ" />
        </ListItem>
      </a>

      <a href="/deleted">
        <ListItem button selected={url === "deleted" ? true : false}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Աղբաման" />
        </ListItem>
      </a>

      <a href="/email">
        <ListItem button selected={url === "email" ? true : false}>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="Նամակ" />
        </ListItem>
      </a>

      <a href="/phone">
        <ListItem button selected={url === "phone" ? true : false}>
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>

          <ListItemText primary="Հեռ. Համարներ" />
        </ListItem>
      </a>

      <a href="/ct-patients">
        <ListItem button selected={false}>
          <ListItemIcon>
            <LocalHospitalIcon />
          </ListItemIcon>

          <ListItemText primary="CT հետազոտություն" />
        </ListItem>
      </a>

      <a href="/spect-patients">
        <ListItem button selected={false}>
          <ListItemIcon>
            <LocalHospitalIcon />
          </ListItemIcon>

          <ListItemText primary="SPECT/CT" />
        </ListItem>
      </a>

      <a href="/pet">
        <ListItem button selected={url === "pet" || sub_url === "pet-list" ? true : false}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>

          <ListItemText primary="Հետազոտվողներ" />
        </ListItem>
      </a>

      {localStorage.getItem("user_name") === "Director" ? <a href="/report">
        <ListItem button selected={url === "pet" || sub_url === "pet-list" ? true : false}>
          <ListItemIcon>
            <AssessmentIcon />
          </ListItemIcon>

          <ListItemText primary="Հաշվետվություն" />
        </ListItem>
      </a> : null}

      <Divider />

      <List>
        <Logout />
      </List>
    </React.Fragment>
  );
}

export default MainListItems;