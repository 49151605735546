import dateFormat from './DateFormat';

function dateCheck(date) {
    if (date === '-') {
        return date;
    }
    
    if (date.includes("/")) {
        var split = date.split('/');
        return dateFormat(split[0], 'yes') + ' մինչև ' + dateFormat(split[1], 'yes');
    } else {
        return dateFormat(date);
    }
}

export default dateCheck;