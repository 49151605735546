import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import Copyright from "../Copyright";
import CircularProgress from "@material-ui/core/CircularProgress";

//Icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [wrongData, setWrongData] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);

  const [passwordShow, isPasswordShow] = useState(false);

  function changeEmail(e) {
    if (e.target.value.length && emailError.length) {
      setEmailError("");
    }

    setEmail(e.target.value);
  }

  function changePassword(e) {
    if (e.target.value.length && passwordError.length) {
      setPasswordError("");
    }

    setPassword(e.target.value);
  }

  function passwordShowFunc(param) {
    isPasswordShow(!param);
  }

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoaded(true);
    fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
      },
    })
      .then(res => res.json())
      .then(res => {
        setIsLoaded(false);
        
        if (res.status === "Error") {
          if (res.data.email) {
            setEmailError(res.data.email);
            setPasswordError("");
          } else if (res.data.password) {
            setEmailError("");
            setPasswordError(res.data.password);
          }

          setWrongData("");
        }

        if (res.error === "Unauthorised") {
          setWrongData("Սխալ Էլ հասցե կամ Գաղտնաբառ");
        }

        if (res.status === "Success") {
          setIsLoaded(true);
          setPasswordError("");
          setEmailError("");

          setWrongData("");

          localStorage.setItem("appname_token", res.success.token);
          localStorage.setItem("user_name", res.userName);

          window.location.reload();
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
                
        <Typography component="h1" variant="h5">
          Մուտք գործել
        </Typography>

        {wrongData ? <FormHelperText error>{wrongData}</FormHelperText> : ""}

        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Էլ հասցե"
            name="email"
            defaultValue={email}
            onChange={changeEmail}
            autoComplete="email"
            autoFocus
          />

          {emailError ? (
            <FormHelperText error>{emailError}</FormHelperText>
          ) : (
            ""
          )}

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Գաղտնաբառ"
            type={!passwordShow ? "password" : "text"}
            id="password"
            defaultValue={password}
            onChange={changePassword}
            autoComplete="current-password"
            InputProps={{
              endAdornment: !passwordShow ? (
                <VisibilityOff
                  cursor="pointer"
                  onClick={() => passwordShowFunc(false)}
                />
              ) : (
                <Visibility
                  cursor="pointer"
                  onClick={() => passwordShowFunc(true)}
                />
              ),
            }}
          />

          {passwordError ? (
            <FormHelperText error>{passwordError}</FormHelperText>
          ) : (
            ""
          )}

          {!isLoaded ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              data-action='submit'
            >
              Մտնել
            </Button>
          ) : (
            <CircularProgress />
          )}
        </form>
      </div>
      <Box>
        <Copyright />
      </Box>
    </Container>
  );
}
